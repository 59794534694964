body,
button,
input,
.asset-grid .asset-counts h4::before,
.header-2-row::before {
    font-family: 'Rubik', sans-serif;
}

body {
    background: #FFFFFF;
    color: #000000;
    font-weight: 300;
    overflow:hidden;
}

a, a:visited {
    text-decoration: unset;
    color: unset;
}

input:focus, button:focus {
    outline: none;
}

img {
    max-width: 100%;
}

buttonx {
    border: 0;
    font-weight: 500;
    background-color: #003974;
    color: #FFFFFF;
    min-height: 50px;
    padding: 0 20px;
    border-radius: 10px;
    text-transform: capitalize;
    cursor: pointer;
    margin: 0;
    box-shadow: 0px 4px 20px rgba(0, 123, 145, 0.15);
}

buttonx:disabled {
    opacity: .75;
    cursor: not-allowed;
}

input {
    padding: 15px 25px;
    margin: 0;
    border: 1px solid #DEDEDE;
    border-radius: 5px;
    transition: border-color .5s ease;
}

input:focus {
    border-color: #3590F3;
}

input::placeholder {
    color: rgba(0, 0, 0, 0.3);
}

input.search {
    background: url(./images/search.svg) calc(100% - 8px) center / 16px no-repeat;
    padding: 8px 40px 8px 0;
    border-width: 0;
    border-bottom-width: 1px;
    border-radius: unset;
}

.asset-grid .tabs-grid input,
.top-nav input[type="checkbox"],
.top-nav input[type="radio"],
.asset-grid .actions input,
.option-grid .checkbox-container input,
.page-indicator input {
    display: none;
}

h1, h2, h3, h4, h5, h6,
.checkout-container .checkout-table,
.checkout-container .confirm-card,
.landing-artist-details .story {
    font-family: 'Abhaya Libre', serif;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    margin: 0;
}

p {
    margin: 0;
    line-height: 34px;
}

.landing-banner p,
.landing-artist p,
.landing-vision-banner p,
.landing-story p,
.landing-artist-details .location,
.fundraising-banner p,
.fundraising-benefits span,
.fundraising-package ul,
.fundraising-case-study ul {
    line-height: 27px;
}
.landing-banner p,
.landing-artist p,
.landing-story p {
    text-align: justify;        
}


ul, li {
    padding: 0;
    margin: 0;
}

.option-grid .option-details ul,
.what-you-get ul,
.fundraising-package ul,
.fundraising-case-study ul {
    padding-left: 20px;
}

h1 {
    font-size: 56px;
    line-height: 66px;
}

h2,
.fundraising-banner h3.header-2-row {
    font-size: 50px;
    line-height: 60px;
}

h3 {
    font-size: 38px;
    line-height: 45px;
}

.page-banner h3,
.header-link h3 {
    font-size: 35px;
    line-height: 41px;
}

h4 {
    font-size: 32px;
    line-height: 38px;
}

.asset-grid .profile-card h3,
.popup-container.options h4,
.asset-grid .bio-tab h4,
.popup-container.claim h4,
.popup-container.claim-received h4,
.landing-artist h3,
.popup-header-container h4,
.fundraising-package .fundraising-package-text h4.header-2-row {
    font-size: 30px;
    line-height: 35px;
}

h5 {
    font-size: 28px;
    line-height: 33px;
}

h6 {
    font-size: 26px;
    line-height: 31px;
}

.footer-socials,
.checkout-container .confirm-card,
.bio-container h6.bio-header,
.terms-container h6.terms-header,
.popup-container.donate .claim-card h4,
.fundraising-banner h3.header-2-row::before {
    font-size: 24px;
    line-height: 28px;
}

.asset-grid .asset-counts h4,
h3.header-2-row::before,
.landing-numbers,
.comparison-table .table-header,
.bio-container h6,
.terms-container h6 {
    font-size: 21px;
    line-height: 26px;
}

input,
.checkout-container .checkout-table .divider ~ span,
.popup-container.options,
.center-header,
.asset-grid .tabs-grid h6,
.crew-grid h6,
.popup-header-container,
.donate-form .donate-form-header-container .donate-form-header {
    font-size: 20px;
    line-height: 24px;
}

button,
.page-banner,
.asset b,
.asset-grid .ascii-container h5,
.option-grid .option-details,
.option-grid .option-name-count b,
.comparison-table .yes-text,
.comparison-table .no-text,
.landing-story h6,
.terms-container .terms-date,
.bio-container .bio-end,
.terms-container .terms-end,
.what-you-get span,
.donate-inputs input,
.fhs-grid p,
.innoverse-grid p,
.arles-grid p {
    font-size: 18px;
    line-height: 29px;
}
.innoverse-grid p,
.arles-grid p {
    max-width: 440px;
    font-weight: 300;
}

body,
.top-nav,
.asset-grid .share::before,
.asset-grid .trading-table .table-header,
.login-container,
.login-container input,
.checkout-container .checkout-table + span,
.collection-item > div > b,
.claim-input-container input,
.landing-artist .profile,
.landing-goals,
h4.header-2-row::before {
    font-size: 16px;
    line-height: 19px;
}

.bottom-nav,
.asset-grid .buy-quantity button,
.asset-grid .like::after,
.option-grid .input-container::before,
.checkout-signup input,
.claim-card button,
.donate-inputs .input-container::before,
.donate-inputs button {
    font-size: 15px;
    line-height: 18px;
}

.asset,
input.search,
.top-nav button,
.asset-grid .profile,
.claim-card .profile,
.asset-grid .details-header > span,
.asset-grid .tabs-grid,
.asset-grid .trading-table > div,
.popup-container.collection .collection-description,
.popup-container.collection .collection-description p,
.option-grid .option-details ul,
.asset-grid .bio-tab .bio-card,
.what-you-get ul,
.donate-signup input,
.asset-grid .buy-quantity,
.fundraising-campaign button {
    font-size: 14px;
    line-height: 17px;
}

.fundraising-campaign button {
    border: 0;
    font-weight: 500;
    background-color: #003974;
    color: #FFFFFF;
    min-height: 50px;
    padding: 0 20px;
    border-radius: 10px;
    text-transform: capitalize;
    cursor: pointer;
    margin: 0;
    box-shadow: 0px 4px 20px rgba(0, 123, 145, 0.15);

}

.asset-grid .asset-counts h4::before,
.asset-grid .details-card p,
.landing-footer p,
.footer-socials,
.checkout-container h6 + span,
.collection-grid,
.option-grid .option-name-count div,
.claim-input-container span,
.asset .price::before,
.crew-grid,
.donated-count,
.donate-inputs {
    font-size: 12px;
    line-height: 14px;
}

.donate-form a,
.donate-fees {
    font-size: 10px;
    line-height: 14px;
}

.top-nav {
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    gap: 105px;
    align-items: center;
    padding: 18px 50px;
    text-transform: uppercase;
    box-shadow: 0px 1px 17px rgba(0, 0, 0, 0.13);
    background-color: #003974;
    color: #FFFFFF;
    position: relative;
    z-index: 3;
}

.top-nav .nav-logo {
    display: flex;
}

.top-nav img {
    height: 28px;
}

.top-nav > div {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    gap: 30px;
    align-items: center;
}

.top-nav input.search {
    color: #FFFFFF;
    border-color: #D8D8D8;
    background-image: url(./images/search-white.svg);
    min-width: 300px;
    box-sizing: border-box;
}

.top-nav input.search::placeholder {
    color: #D8D8D8;
}

.top-nav button {
    min-height: 36px;
    color: #000000;
    background: #050202 url(./images/arrow.svg) calc(100% - 20px) center / auto 10px no-repeat;
    padding-right: 40px;
    border-radius: 7px;
}

.top-nav .profile {
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background: url(./images/profile-white.svg) center / contain no-repeat;
    cursor: pointer;
}

.top-nav .nav-links label {
    position: relative;
}

.top-nav .nav-links a {
    letter-spacing: 0.145em;
}

.top-nav .nav-links a::after {
    content: '';
    width: 0;
    height: 3px;
    background-color: #A39FEA;
    position: absolute;
    left: 50%;
    bottom: -6px;
    transform: translate(-50%, 100%);
    transition: width .3s ease;
}

.top-nav .nav-links :checked + a::after {
    width: 20px;
}

.mobile-nav {
    position: absolute;
    top: 70px;
    right: 0;
    background-color: #FFFFFF;
    z-index: 2;
    display: grid;
    padding: 20px;
    gap: 40px;
    text-transform: uppercase;
    box-shadow: 0px 1px 16px rgb(0 0 0 / 19%);
}

.mobile-nav span {
    cursor: pointer;
}

.show-menu-enter {
    transform: translateY(-100%);
}
.show-menu-enter-active {
    transform: translateY(0);
    transition: transform .5s ease;
}
.show-menu-exit {
    transform: translateY(0);
}
.show-menu-exit-active {
    transform: translateY(-100%);
    transition: transform .5s ease;
}

.crew-grid {
    padding: 0 100px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 80px;
    margin-bottom: 40px;
}

.crew-grid span {
    display: grid;
    text-align: center;
    gap: 10px;
    grid-auto-rows: max-content;
}

.crew-grid span::before {
    content: '';
    width: 190px;
    height: 190px;
    border-radius: 50%;
    background: var(--backgroundImage) center / cover no-repeat;
    margin: 0 auto;
}

.crew-grid h6 {
    margin-top: 10px;
}

.partners-grid {
    display: flex;
    padding: 0 80px 40px;
}

.partners-grid img {
    margin: auto;
    width: 150px;
}

.landing-footer {
    padding: 15px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(180deg, rgba(136, 157, 208, 0.21) 0%, rgba(166, 163, 217, 0.21) 100%);
}

.landing-footer img {
    height: 22px;
}

.landing-footer p {
    max-width: 270px;
    margin-top: 10px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.7);
}

.footer-socials {
    font-weight: 500;
    color: #003974;
}

.footer-socials > div {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 25px;
    grid-auto-rows: 25px;
    gap: 20px;
    margin-top: 15px;
    justify-content: center;
}

.footer-socials a {
    background: center / contain no-repeat;
}

.footer-socials a.linkedin,
.footer-socials a.whatsapp,
.footer-socials a.facebook,
.footer-socials a.instagram {
    background-size: 24px;
}

.footer-socials a.linkedin {
    background-image: url(./images/linkedin.svg);
    background-position-y: 40%;
}

.footer-socials a.email {
    background-image: url(./images/mail.svg);
    background-position-y: 60%;
}

.footer-socials a.whatsapp {
    background-image: url(./images/whatsapp.svg);
}

.footer-socials a.instagram {
    background-image: url(./images/instagram.svg);
}

.footer-socials a.facebook {
    background-image: url(./images/facebook-brands.svg);
}


.bottom-nav {
    display: flex;
    justify-content: space-between;
    color: rgba(255, 255, 255, 0.6);
    padding: 20px 25px;
    background-color: #003974;
}

.page-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 390px;
    padding: 0 80px;
    color: #FFFFFF;
    line-height: 28px;
    --align: left;
    --background: unset;
    --gradient-start: rgba(5, 48, 4, 0.87) 1.16%;
    --gradient-end: rgba(4, 82, 12, 0) 99.97%;
    background:
        linear-gradient(90deg, var(--gradient-start), var(--gradient-end)) left center / 60% 100% no-repeat,
        var(--background) var(--align) bottom / cover no-repeat;
}

.page-banner div {
    position: relative;
}

.page-banner div::before {
    content: '';
    display: block;
    width: 25px;
    height: 20px;
    background-color: #0D5B1A;
    position: absolute;
    top: 2px;
    left: -8px;
}

.page-banner h3 {
    text-transform: capitalize;
    position: relative;
}

.explore-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
}

.explore-container .loading-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 65px 0;
    font-weight: 400;
    color: #003974;
    text-transform: uppercase;
    opacity: 0;
    transition: opacity .5s ease;
}

.explore-container .loading-banner.loading {
    opacity: 1;
}

.explore-container .loading-banner .lds-spinner {
    margin-right: 9px;
    --color: #003974;
}

.popup-container.claim button .lds-spinner,
.login-container .login-submit button .lds-spinner,
.popup-container.donate button .lds-spinner {
    --color: #FFFFFF;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
}

.popup-container.claim button .lds-spinner,
.popup-container.donate button .lds-spinner {
    right: 10px;
}

.explore-search {
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 60px;
    background-color: #FFFFFF;
    z-index: 1;
    transition: box-shadow .3s ease;
    color: #7C7C7C;
    min-height: 79px;
    box-sizing: border-box;
}

.explore-search.scrolling {
    box-shadow: -1px 2px 17px rgba(0, 0, 0, 0.1);
}

.explore-search-grid {
    display: grid;
    grid-auto-flow: column;
    gap: 20px;
    color: #000000;
    font-weight: 400;
    margin-right: auto;
}

.explore-search-grid a {
    padding: 9px 35px 9px 14px;
    border: 1px solid #D5F7FF;
    border-radius: 5px;
    background: url(./images/close-orange.svg) calc(100% - 14px) center / 13px no-repeat;
}

.explore-search .search-container {
    position: relative;
}

.explore-search .search-container input {
    width: 100%;
    box-sizing: border-box;
}

.explore-search .search-container input.loading {
    background-image: unset;
}

.explore-search .search-container .lds-spinner {
    position: absolute;
    top: 50%;
    right: 4px;
    transform: translateY(-50%);
}

.assets-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px 55px;
    padding: 0 58px;
    margin-top: 20px;
}

.assets-grid.controls-grid {
    padding-top: 30px;
    padding-bottom: 150px;
    margin-top: 0;
}

.asset {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

.asset .price::before {
    content: attr(data-pricelabel);
    display: block;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.7);
    letter-spacing: 1px;
    margin-bottom: 5px;
}

.asset .profile {
    margin-top: 4px;
}

.asset::before,
.collection-item::before {
    content: '';
    display: block;
    padding-bottom: var(--paddingBottom);
    background: var(--backgroundImage) center / cover no-repeat;
    border-radius: 10px 10px 0 0;
}

.asset > div {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-rows: 1fr max-content;
    gap: 6px 10px;
    padding: 10px 20px 14px;
    word-break: break-word;
    flex-grow: 1;
}

.asset b {
    font-weight: 500;
    grid-column-end: span 2;
}

.asset-grid-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    overflow-y: scroll;
    overscroll-behavior-y: contain;
    background-color: #FFFFFF;
}

.asset-grid-container.innoverse-theme {
    background-color: transparent;
}

.asset-grid {
    display: grid;
    grid-template-columns: 54% 1fr;
    grid-template-rows: max-content 1fr max-content;
    gap: 25px 30px;
    padding: 66px;
    background: #FFFFFF linear-gradient(180deg, rgba(136, 157, 208, 0.21) 0%, rgba(166, 163, 217, 0.21) 100%);
}

.asset-grid.innoverse-theme{
    padding-top: 195px;
    background: transparent;
}


.asset-grid > div,
.asset-grid .trading-table {
    border-radius: 15px;
    padding: 50px;
    background-color: #FFFFFF;
    position: relative;
    border: 1px solid #E9E9E9;
}

.asset-grid .image-card {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: 0;
    margin: auto;
    grid-row-end: span 2;
    cursor: pointer;
}

.asset-grid .image-card img,
.asset-grid .image-card video {
    max-width: 100%;
    max-height: calc(100vh - 230px);
    border-radius: 20px;
}

.asset-grid .show-full,
.asset-grid .share,
.asset-grid .like::before {
    width: 22px;
    height: 22px;
    cursor: pointer;
}

.asset-grid .show-full {
    position: absolute;
    background: url(./images/expand.svg) center / contain no-repeat;
    bottom: 24px;
    right: 28px;
    filter: drop-shadow(0px 2px 4px rgba(0,0,0,0.5));
}

.asset-grid .share {
    position: relative;
    background: url(./images/share.svg) center / contain no-repeat;
    -webkit-tap-highlight-color: transparent;
}

.asset-grid .like {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    gap: 7px;
    align-items: center;
    cursor: pointer;
}

.asset-grid .like::before {
    content: '';
    background: url(./images/like.svg) center / contain no-repeat;
}

.asset-grid :checked + .like::before {
    background-image: url(./images/liked.svg);
}

.asset-grid .like::after {
    content: attr(data-message);
    font-weight: 500;
}

.asset-grid .profile-card {
    padding: 35px 35px 30px;
    display: grid;
    gap: 30px;
}

.asset-grid .profile-ascii {
    display: flex;
    justify-content: space-between;
    gap: 30px;
}

.asset-grid .profile-card h3 {
    word-break: break-word;
}

.asset-grid .title-profile {
    display: grid;
    gap: 23px;
    grid-auto-rows: max-content;
}

.asset-grid .profile,
.collection-description .profile,
.claim-card .profile,
.asset .profile,
.landing-story .profile,
.landing-artist .profile {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 8px;
    color: #7C7C7C;
    align-items: center;
    word-break: break-word;
    --profile: url(./images/profile.svg);
}

.asset-grid .bio-tab .profile {
    grid-template-columns: 1fr;
}

.asset .profile,
.landing-story .profile,
.landing-artist .profile {
    color: #000000;
}

.landing-story .profile,
.landing-artist .profile {
    gap: 15px;
    text-align: left;
}

.asset-grid .profile::before,
.collection-description .profile::before,
.claim-card .profile::before,
.asset .profile::before,
.landing-story .profile::before,
.landing-artist .profile::before {
    content: '';
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: var(--profile) center / cover no-repeat;
}

.collection-description .profile::before,
.asset .profile::before {
    width: 31px;
    height: 31px;
}

.landing-artist .profile::before {
    width: 73px;
    height: 73px;
}

.asset-grid .bio-tab .profile::before {
    width: 230px;
    height: 230px;
    align-self: flex-start;
    margin-top: 35px;
}

.asset-grid .ascii-container {
    text-align: center;
    display: grid;
    gap: 10px;
    grid-auto-rows: max-content;
    flex-shrink: 0;
}

.asset-grid .ascii-container h5 {
    font-weight: 400;
}

.asset-grid .ascii-container img {
    height: 75px;
    border-radius: 2px;
    margin: auto;
}

.asset-grid .asset-counts {
    display: flex;
    flex-wrap: wrap;
    gap: 20px 40px;
}

.asset-grid .asset-counts h4 {
    font-weight: 400;
    display: grid;
    gap: 5px;
    white-space: nowrap;
}

.asset-grid .asset-counts h4::before {
    content: attr(data-message);
    text-transform: capitalize;
    color: #7C7C7C;
    font-weight: 300;
}

.asset-grid .buy-quantity {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    color: #003974;
    font-weight: 400;
    text-transform: capitalize;
}

.asset-grid .buy-quantity button {
    min-width: 165px;
}

.asset-grid .buy-quantity span {
    display: flex;
    align-items: center;
    gap: 6px;
}

.asset-grid .buy-quantity span::before {
    content: '';
    width: 17px;
    height: 17px;
    background: url(./images/quantity.svg) center / cover no-repeat;
    flex-shrink: 0;
}

.asset-grid .share::before {
    content: attr(data-message);
    position: absolute;
    display: block;
    background-color: #FFFFFF;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.15);
    top: -10px;
    left: 50%;
    transform: translate(-50%, -100%);
    white-space: nowrap;
    padding: 10px 20px;
    opacity: 0;
    transition: opacity .5s ease;
    pointer-events: none;
}

.asset-grid .share.copied::before {
    opacity: 1;
}

.asset-grid .tabs-grid {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    gap: 88px;
    border-bottom: 1px solid #EFEFEF;
    color: #003974;
    margin: 0 20px;
}

.asset-grid .tabs-grid h6 {
    padding-bottom: 20px;
    text-transform: capitalize;
    cursor: pointer;
    transition: border-bottom-color .5s ease;
    border-bottom: 4px solid transparent;
    font-weight: 400;
}

.asset-grid .tabs-grid :checked + h6 {
    border-bottom-color: #003974;
    cursor: default;
}

.asset-grid .details-card {
    display: grid;
    grid-auto-rows: max-content;
    gap: 15px;
    padding: 25px 25px 35px;
}

.asset-grid .details-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.asset-grid .details-header > span {
    text-transform: uppercase;
    letter-spacing: 3px;
}

.asset-grid .actions {
    display: grid;
    grid-auto-flow: column;
    gap: 30px;
    align-items: center;
}

.asset-grid .details-card p {
    margin-top: 5px;
    color: rgba(0, 0, 0, 0.7);
    word-break: break-word;
    line-height: 20px;
}

.asset-grid .esg-logo {
    width: 105px;
}

.asset-grid .tabs-card {
    grid-column-end: span 2;
    padding: 30px 20px;
    margin-top: 15px;
    margin-bottom: 100px;
}

.asset-grid .trading-table {
    margin-top: 30px;
    padding: 0 0 24px;
}

.asset-grid .trading-table h5 {
    padding: 35px 40px;
    text-transform: capitalize;
}

.asset-grid .trading-table h5::before {
    content: '';
    display: inline-block;
    width: 22px;
    height: 17px;
    background: url(./images/trade.svg) center / contain no-repeat;
    margin-right: 20px;
}

.asset-grid .trading-table > div {
    display: grid;
    grid-template-columns: 250px 1fr 75px repeat(3, 1fr);
    padding: 12px 65px;
    gap: 10px;
    line-height: 28px;
    word-break: break-word;
}

.asset-grid .trading-table .table-header {
    padding-top: 18px;
    padding-bottom: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
    background-color: #003974;
    font-weight: 400;
    letter-spacing: 2px;
    margin-bottom: 24px;
}

.asset-grid .trading-table .event-create,
.asset-grid .trading-table .event-payment {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    gap: 12px;
    align-items: center;
    margin-bottom: auto;
}

.asset-grid .trading-table .event-create::before,
.asset-grid .trading-table .event-payment::before {
    content: '';
    width: 20px;
    height: 28px;
    background: url(./images/event-list.svg) center / contain no-repeat;
}

.asset-grid .trading-table .event-payment::before {
    background-image: url(./images/payment.svg);
}

.asset-grid .bio-tab,
.goal-grid {
    margin: 60px 40px 50px;
}

.asset-grid .bio-tab {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 65px;
}

.asset-grid .bio-tab .bio-card {
    padding: 45px 70px;
    background-color: #CAC8E8;
    border-radius: 7px;
    position: relative;
    display: grid;
    gap: 35px;
    line-height: 24px;
    white-space: pre-line;
}

.asset-grid .bio-tab .bio-card::before {
    content: '';
    position: absolute;
    left: -23px;
    top: 110px;
    width: 0;
    height: 0;
    border: 23px solid transparent;
    border-right-color: #CAC8E8;
    border-left: 0;
}

.asset-grid .bio-tab h4 {
    color: #003974;
}

.popup-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
}

.popup-container.show-popup-enter,
.popup-container.show-popup-appear {
    opacity: 0;
}

.popup-container.show-popup-enter-active,
.popup-container.show-popup-appear-active {
    opacity: 1;
    transition: opacity .5s ease;
}

.popup-container.show-popup-exit {
    opacity: 1;
}

.popup-container.show-popup-exit-active {
    opacity: 0;
    transition: opacity .5s ease;
}

.popup-container.show-popup-enter > div,
.popup-container.show-popup-appear > div {
    transform: translateY(50%);
}

.popup-container.show-popup-enter-active > div,
.popup-container.show-popup-appear-active > div {
    transform: translateY(0%);
    transition: transform .5s ease;
}

.popup-container.show-popup-exit > div {
    transform: translateY(0%);
}

.popup-container.show-popup-exit-active > div {
    transform: translateY(50%);
    transition: transform .5s ease;
}

.popup-container.full-image {
    cursor: pointer;
}

.popup-container.full-image img {
    max-width: 90vw;
    max-height: 90vh;
    padding-top: 80px;
    cursor: default;
}

.popup-container.full-image video {
    max-width: 90vw;
    max-height: 90vh;
    cursor: default;
}


.login-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    min-height: calc(100vh - 60px);
}

.login-container::before {
    content: '';
    background: url(./images/login-banner.jpg) center / cover no-repeat;
}

.login-container form {
    display: grid;
    gap: 15px;
    grid-auto-rows: max-content;
    padding: 55px 80px;
}

.login-container form h3 {
    margin-bottom: 10px;
}

.login-container form label {
    display: grid;
    gap: 10px;
}

.login-container form label span {
    text-transform: uppercase;
}

.login-container input {
    padding: 15px 20px;
}

.login-container .login-submit {
    margin-top: 10px;
    text-transform: uppercase;
}

.login-container .login-submit button {
    margin-right: 25px;
    min-width: 185px;
    position: relative;
}

.innoverse-grid a {
    color:#50B748
}
.t-and-c a,
.login-container .login-submit a,
.login-container a.forgot,
.arles-grid a,
.minisaur-grid a,
.bff-grid p a {
    color: #3590F3;
}

.login-container .login-submit a:hover,
.login-container a.forgot:hover,
.donate-form a:hover {
    text-decoration: underline;
}

.login-container a.forgot {
    margin-right: auto;
    text-transform: capitalize;
}

.login-container .google-signin {
    width: 200px;
    margin-bottom: 10px;
    cursor: pointer;
}

.page-banner.checkout-banner {
    height: 290px;
    background: url(./images/checkout-banner.jpg) center / cover no-repeat;
}

.page-banner.checkout-banner div::before {
    background-color: #779B55;
    width: 32px;
    height: 17px;
    top: 0;
}

.checkout-container {
    display: grid;
    grid-template-columns: 1fr repeat(3, max-content);
    gap: 75px;
    align-items: center;
    padding: 75px 95px 60px;
}

.checkout-container img {
    border-radius: 4px;
    max-height: 500px;
    margin: auto;
}

.checkout-container h6 {
    max-width: 250px;
    word-break: break-word;
}

.checkout-container h6 + span {
    color: #7C7C7C;
    margin-top: 5px;
    display: block;
}

.checkout-container .checkout-table {
    margin: 25px 0 30px;
    display: grid;
    gap: 5px 30px;
    grid-template-columns: 1fr max-content;
    color: rgba(0, 0, 0, .7);
}

.checkout-container .checkout-table span:nth-child(even) {
    text-align: right;
}

.checkout-container .checkout-table .divider {
    grid-column-end: span 2;
    border-top: 1px dashed #C8C8C8;
    margin: 5px 0;
}

.checkout-container .checkout-table .divider ~ span {
    color: #000000;
    text-align: left;
}

.checkout-container .checkout-table + span {
    color: #FF6961;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
}

.checkout-container > .divider {
    border-left: 1px solid rgba(0, 0, 0, 0.6);
    height: 100%;
}

.checkout-container .confirm-card {
    padding: 45px 60px 40px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
    border-radius: 7px;
    display: grid;
    gap: 25px;
}

.checkout-container .confirm-card button {
    margin-top: 10px;
    min-width: 260px;
}

.checkout-container .confirm-card .divider {
    border-top: 1px solid rgba(0, 0, 0, 0.6);
}

.page-banner.collection-banner {
    --background: url(./images/collection-banner.jpg);
    --gradient-start: rgb(0 57 116) 1.16% 1.16%;
    --gradient-end: rgba(0, 57, 116, 0) 99.97%;
}

.page-banner.collection-banner div::before {
    width: 60px;
    height: 48px;
    top: -20px;
    left: -20px;
    background-color: rgba(53, 144, 243, 0.49);
}

.collection-grid {
    padding: 75px 80px 60px;
}

.collection-grid .masonry-gutter {
    width: 60px;
}

.collection-item {
    width: calc((100% - 120px - 160px) / 3);
    margin-bottom: 60px;
    box-shadow: 7px 7px 30px rgba(160, 160, 160, 0.2);
    border-radius: 7px;
    overflow: hidden;
    cursor: pointer;
}

.collection-item > div {
    display: grid;
    gap: 8px;
    padding: 20px 25px;
}

.collection-item > div > b {
    margin-bottom: 12px;
    word-break: break-word;
}

.popup-container.collection > div {
    display: grid;
    grid-template-columns: 1fr 475px;
    background-color: #FFFFFF;
    padding: 50px 45px;
    max-width: 90%;
    box-sizing: border-box;
}

.popup-container.collection img {
    max-height: calc(80vh - 100px);
    margin: auto;
}

.popup-container.collection .collection-description {
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.popup-container.collection .collection-description h6 {
    word-break: break-word;
}

.popup-container.collection .collection-description p {
    margin-top: 5px;
    max-height: 250px;
    overflow-y: scroll;
    overscroll-behavior-y: contain;
}

.popup-container.collection .collection-description .divider {
    border-top: 1px solid #C7C7C7;
}

.popup-container.options h4 {
    text-align: center;
    margin-bottom: 55px;
}

.popup-container.options > div,
.popup-container.claim-received > div {
    background-color: #FFFFFF;
    padding: 80px 65px 0;
    max-height: 90vh;
    box-sizing: border-box;
    overflow-y: scroll;
}

.popup-container.options > div::after,
.popup-container.claim-received > div::after {
    content: '';
    height: 70px;
    display: block;
}

.popup-container.claim-received > div::after {
    height: 25px;
    flex-shrink: 0;
}

.option-grid {
    display: grid;
    gap: 20px;
    margin-top: 36px;
}

.option-grid > label {
    border: 1px solid #C2C2C2;
    padding: 20px;
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 20px 15px;
    cursor: pointer;
    box-sizing: border-box;
    width: 630px;
}

.option-grid .checkbox-container {
    grid-row-end: span 2;
}

.option-grid .checkbox-container div {
    width: 24px;
    height: 24px;
    border: 1px solid #3590F3;
    border-radius: 50%;
    box-sizing: border-box;
}

.option-grid .checkbox-container :checked + div {
    background: #3590F3 url(./images/check.svg) center / 12px no-repeat;
}

.option-grid .option-name-count {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    gap: 7px;
}

.option-grid .option-name-count span,
.option-grid .option-details span {
    font-weight: 400;
}

.option-grid .option-name-count span {
    text-transform: uppercase;
}

.option-grid .option-name-count div,
.claim-input-container span,
.donated-count {
    padding: 6px 24px;
    background-color: #F0F2F9;
    border-radius: 2px;
}

.option-grid .option-details {
    display: grid;
    gap: 10px;
}

.option-grid .option-details ul {
    color: #767676;
}

.option-grid .input-container,
.donate-inputs .input-container {
    display: flex;
}

.option-grid .input-container {
    margin-top: 20px;
}

.option-grid .input-container::before,
.donate-inputs .input-container::before {
    content: attr(data-currency);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    color: #FFFFFF;
    background-color: #003974;
    text-transform: uppercase;
    font-weight: 500;
}

.option-grid .input-container input,
.donate-inputs .input-container input {
    padding: 10px;
    border-radius: unset;
    width: 150px;
    box-sizing: border-box;
    border-left: 0;
}

.option-grid button {
    margin-left: auto;
}

.option-grid .input-container button {
    min-height: unset;
    margin-left: 20px;
    background-color: #003974;
}

.checkout-signup {
    display: grid;
    gap: 5px;
}

.checkout-signup input {
    padding: 10px 15px;
}

.goal-grid {
    display: flex;
    flex-wrap: wrap;
}

.goal-icon {
    width: 195px;
    height: 195px;
    background: center / contain no-repeat;
    margin: 0 12px 12px 0;
}

.goal-icon.good-health-and-well-being {
    background-image: url(./images/goals/good-health-and-well-being.png);
}

.goal-icon.clean-water-and-sanitation {
    background-image: url(./images/goals/clean-water-and-sanitation.png);
}

.goal-icon.affordable-and-clean-energy {
    background-image: url(./images/goals/affordable-and-clean-energy.png);
}

.goal-icon.industry-innovation-and-infrastructure {
    background-image: url(./images/goals/industry-innovation-and-infrastructure.png);
}

.goal-icon.sustainable-cities-and-communities {
    background-image: url(./images/goals/sustainable-cities-and-communities.png);
}

.goal-icon.responsible-consumption-and-production {
    background-image: url(./images/goals/responsible-consumption-and-production.png);
}

.goal-icon.climate-action {
    background-image: url(./images/goals/climate-action.png);
}

.goal-icon.life-on-land {
    background-image: url(./images/goals/life-on-land.png);
}

.popup-container.claim > div {
    background-color: #FFFFFF;
    padding: 40px 65px 60px;
    max-width: 760px;
    box-sizing: border-box;
}

.popup-container.claim > div > h4 {
    text-align: center;
    margin-bottom: 20px;
}

.center-header {
    display: grid;
    text-align: center;
    gap: 10px;
}

.claim-card {
    display: grid;
    gap: 15px;
    border: 1px solid #C2C2C2;
    padding: 20px 20px 30px;
}

.claim-card img {
    display: block;
    max-height: 280px;
    margin: 0 auto;
    border-radius: 7px;
}

.claim-title-profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px 60px;
    margin-bottom: 25px;
}

.claim-title-profile h4 {
    word-break: break-word;
}

.claim-card .claim-title-profile .profile {
    flex-shrink: 0;
}

.claim-input-container {
    --button-width: 160px;
    position: relative;
    display: flex;
}

.claim-input-container input {
    --margin: 15px;
    padding: 12px 15px 13px;
    margin-right: var(--margin);
    width: calc(50% - var(--button-width) / 2 - var(--margin));
    pointer-events: none;
    opacity: 0;
    transition: all .5s ease;
    box-sizing: border-box;
}

.claim-input-container input.expand {
    width: calc(100% - var(--button-width) - var(--margin));
    pointer-events: all;
    opacity: 1;
}

.claim-input-container button {
    min-width: var(--button-width);
    min-height: 46px;
    position: relative;
    z-index: 1;
}

.claim-input-container span {
    padding: 6px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity .3s .2s ease;
}

.claim-input-container input.expand ~ span {
    opacity: 0;
}

.popup-container.claim-received > div {
    display: flex;
    flex-direction: column;
    max-width: 760px; /* .option-grid > label is fixed to 630px */
}

.popup-container.claim-received > div > h4 {
    text-align: center;
    margin-bottom: 25px;
}

.popup-container.claim-received .claim-card {
    padding-bottom: 15px;
    margin: 0 auto 40px;
}

.popup-container.claim-received .claim-card img {
    max-height: 400px;
}

.popup-container.claim-received .claim-card .profile {
    margin-left: auto;
}

.landing-banner {
    display: grid;
    grid-template-columns: 385px max-content;
    justify-content: space-between;
    align-items: center;
    padding: 75px 150px 85px;
    overflow: hidden;
}

.landing-banner h1 {
    width: 370px;
    text-transform: capitalize;
}

.landing-banner-logo {
    padding: 75px;
}
.landing-banner-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.landing-banner-text a {
    margin-top: 15px;
}

.landing-banner button {

    border: 0;
    font-weight: 500;
    background-color: #003974;
    color: #FFFFFF;
    min-height: 50px;
    border-radius: 10px;
    text-transform: capitalize;
    cursor: pointer;
    margin: 0;
    box-shadow: 0px 4px 20px rgba(0, 123, 145, 0.15);
    

    padding: 0 65px 0 45px;
    background: #003974 url(./images/arrow-white.svg) calc(100% - 40px) center / auto 14px no-repeat;
}

.landing-banner .landing-banner-images {
    display: grid;
    grid-template-columns: 285px 255px;
    grid-template-rows: 215px 250px;
    align-items: center;
    gap: 27px 44px;
    margin-left: 40px;
}

.landing-banner .landing-banner-images::before {
    content: '';
    width: 340px;
    height: 390px;
    position: relative;
    top: 0;
    right: 0;
    background: linear-gradient(129.49deg, rgba(187, 232, 94, 0.35) 9.6%, rgba(17, 224, 174, 0.168) 103.33%, rgba(5, 155, 202, 0.168) 103.33%);
    border-bottom-left-radius: 10px;
}

.landing-banner .landing-banner-images div:not(.landing-banner-image-1),
.landing-banner .landing-banner-images div.landing-banner-image-1::before {
    background: center / cover no-repeat;
    border-radius: 12px;
    filter: drop-shadow(0px 3px 44px rgba(0, 0, 0, 0.25));
}

.landing-banner .landing-banner-images div.landing-banner-image-1 {
    position: relative;
    grid-row-end: span 2;
}

.landing-banner .landing-banner-images div.landing-banner-image-1::before {
    content: '';
    display: block;
    height: 250px;
    background-image: url(./images/landing-banner-image-1.jpg);
    position: relative;
    z-index: 1;
}

.landing-banner .landing-banner-images div.landing-banner-image-1::after {
    content: '';
    width: 265px;
    height: 240px;
    background: linear-gradient(135.8deg, rgba(0, 163, 255, 0.1248) 0%, rgba(0, 18, 181, 0.2132) 106.47%);
    border-radius: 24px;
    position: absolute;
    left: -40px;
    top: -44px;
}

.landing-banner .landing-banner-images div.landing-banner-image-2 {
    margin-right: 35px;
    height: 100%;
    background-image: url(./images/landing-banner-image-2.jpg);
}

.landing-banner .landing-banner-images div.landing-banner-image-3 {
    height: 100%;
    background-image: url(./images/landing-banner-image-3.jpg);
}

.header-link,
.comparison-table > div,
.fundraising-package {
    padding: 0 150px;
}

.landing-imgs {
    padding: 30px 150px;
}

.landing-goals {
    padding: 30px 150px;
}

.header-link {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 45px;
}

.header-link a {
    color: #003974;
    font-weight: 500;
    text-transform: uppercase;
}

.header-link h3::before {
    content: '';
    display: inline-block;
    width: 25px;
    height: 31px;
    background: url(./images/hot-drops.svg) center / contain no-repeat;
    vertical-align: top;
    margin-right: 20px;
}

.hot-drops,
.landing-artists,
.landing-stories,
.fundraising-campaigns,
.fundraising-case-studies {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    overflow-x: scroll;
    scroll-behavior: smooth;
}

.crew-grid,
.hot-drops,
.landing-artists,
.landing-stories,
.fundraising-campaigns,
.fundraising-case-studies,
.fundraising-benefits {
    scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.crew-grid::-webkit-scrollbar,
.hot-drops::-webkit-scrollbar,
.landing-artists::-webkit-scrollbar,
.landing-stories::-webkit-scrollbar,
.fundraising-campaigns::-webkit-scrollbar,
.fundraising-case-studies::-webkit-scrollbar,
.fundraising-benefits::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for Webkit browsers, e.g. Chrome, Safari and Chromium Edge */
}

.hot-drops {
    gap: 25px;
    padding-left: 150px;
}

.hot-drops::after {
    content: '';
    width: 125px;
}

.hot-drops + .arrows-container {
    margin-bottom: 50px;
}

.hot-drops .asset {
    width: 345px;
    margin-bottom: 18px;
}

.header-2-row {
    display: grid;
    text-align: center;
    padding: 80px 0 50px;
    text-transform: capitalize;
}

h3.header-2-row {
    letter-spacing: 5px;
}

h4.header-2-row {
    letter-spacing: .035em;
}

.header-2-row.explore-header,
.header-2-row.innoverse-header,
.header-2-row.arles-header {
    padding: 190px 0 70px;
}

.header-2-row::before {
    content: attr(data-before);
    font-weight: 300;
    text-transform: uppercase;
}

h3.header-2-row::before {
    letter-spacing: .5em;
    text-indent: .5em;
}

h4.header-2-row::before {
    letter-spacing: .3em;
    text-indent: .3em;
}

.header-2-row.reverse::before {
    order: 1;
}

.landing-numbers {
    display: grid;
    grid-template-columns: 18.75% 5.05% 13.89% 6.39% 12.15% 23.13% 3.82% 1fr;
    gap: 7px;
}

.landing-numbers div {
    background: center / cover no-repeat;
}

.landing-numbers div.landing-number-1 {
    background-image: url(./images/landing-number-1.jpg);
    padding-bottom: 55.1%;
    grid-column-end: span 2;
}

.landing-numbers div.landing-number-2 {
    background-image: url(./images/landing-number-2.jpg);
    padding-bottom: 64.73%;
    grid-column-end: span 2;
}

.landing-numbers div.landing-number-3 {
    background-image: url(./images/landing-number-3.jpg);
    padding-bottom: 156%;
    grid-row-end: span 2;
}

.landing-numbers div.landing-number-4 {
    background-image: url(./images/landing-number-4.jpg);
    padding-bottom: 134.5%;
    grid-row-end: span 4;
}

.landing-numbers div.landing-number-5 {
    background-image: url(./images/landing-number-5.jpg);
    padding-bottom: 100%;
    grid-column-end: span 2;
    grid-row-end: span 2;
}

.landing-numbers div.landing-number-6 {
    background-image: url(./images/landing-number-6.jpg);
    padding-bottom: 62.22%;
    grid-row-end: span 2;
}

.landing-numbers div.landing-artworks-number {
    grid-column-end: span 3;
    grid-row-end: span 3;
}

.landing-numbers div.landing-number-7 {
    background-image: url(./images/landing-number-7.jpg);
    padding-bottom: 92.31%;
    grid-row-end: span 2;
}

.landing-numbers div.landing-number-8 {
    background-image: url(./images/landing-number-8.jpg);
    padding-bottom: 64.18%;
    grid-column-end: span 2;
    grid-row-end: span 2;
}

.landing-numbers div.landing-number-9 {
    background-image: url(./images/landing-number-9.jpg);
    padding-bottom: 125.6%;
    grid-row-end: span 4;
}

.landing-numbers div.landing-number-10 {
    background-image: url(./images/landing-number-10.jpg);
    padding-bottom: 66.78%;
    grid-column-end: span 2;
}

.landing-numbers div.landing-number-11 {
    background-image: url(./images/landing-number-11.jpg);
    padding-bottom: 69.96%;
    grid-column-end: span 2;
}

.landing-numbers div.landing-artists-number {
    grid-column-end: span 2;
    grid-row-end: span 2;
}

.landing-numbers div.landing-number-12 {
    background-image: url(./images/landing-number-12.jpg);
    padding-bottom: 131.9%;
    grid-row-end: span 4;
}

.landing-numbers div.landing-number-13 {
    background-image: url(./images/landing-number-13.jpg);
    padding-bottom: 65.93%;
    grid-row-end: span 2;
}

.landing-numbers div.landing-number-14 {
    background-image: url(./images/landing-number-14.jpg);
    padding-bottom: 67.04%;
    grid-column-end: span 3;
    grid-row-end: span 4;
    grid-row-start: 6;
    grid-column-start: 2;
}

.landing-numbers div.landing-number-15 {
    background-image: url(./images/landing-number-15.jpg);
    padding-bottom: 114.3%;
    grid-row-end: span 4;
    grid-row-start: 6;
    grid-column-start: 5;
}

.landing-numbers div.landing-number-16 {
    background-image: url(./images/landing-number-16.jpg);
    padding-bottom: 53.93%;
    grid-column-end: span 2;
    grid-row-end: span 3;
    grid-row-start: 7;
}

.landing-numbers div.landing-number-17 {
    background-image: url(./images/landing-number-17.jpg);
    padding-bottom: 70.89%;
}

.landing-numbers div.landing-artworks-number,
.landing-numbers div.landing-artists-number {
    display: grid;
    margin: auto;
    gap: 4px;
    padding: 63px 0;
}

.landing-numbers h3 {
    position: relative;
}

.landing-numbers h3::before {
    content: '';
    display: block;
    width: 35px;
    height: 21px;
    background-color: #DBECFF;
    position: absolute;
    z-index: -1;
    bottom: 8px;
    left: -23px;
}

.landing-numbers div.landing-artists-number h3::before {
    width: 44px;
    height: 13px;
    left: unset;
    right: -11px;
    bottom: 9px;
    background-color: #D8D6F1;
}

.landing-numbers span {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.landing-artists,
.landing-stories,
.fundraising-campaigns,
.fundraising-case-studies {
    --padding: 0;
    --page_items: 1;
    gap: var(--padding);
    padding-left: var(--padding);
    scroll-snap-type: x mandatory;
    scroll-padding: 0 var(--padding);
}

.landing-artists::after,
.landing-stories::after,
.fundraising-campaigns::after,
.fundraising-case-studies::after {
    content: '';
    width: 1px;
    margin-left: -1px;
}

.landing-artist,
.landing-story,
.fundraising-campaign,
.fundraising-case-study-container {
    --menuwidth: 240px;
    width: calc(
        (100vw - var(--menuwidth) - (var(--padding) * (var(--page_items) + 1))) / var(--page_items)
    );
    display: grid;
    align-items: center;
    scroll-snap-align: start;
}

.landing-artist {
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
}

.landing-artist-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;
    text-align: left;
}

.landing-artist-details .location {
    font-weight: 500;
}

.landing-artist-details .location::before {
    content: '';
    display: inline-block;
    width: 11px;
    height: 14px;
    background: url(./images/location.svg) center / contain no-repeat;
    margin-right: 7px;
}

.landing-artist-details .story {
    padding: 25px 35px;
    background-color: rgba(163, 159, 234, 0.33);
    position: relative;
    font-weight: 400;
    line-height: 23px;
    margin-top: 20px;
}

.landing-artist-details .story::before {
    content: '';
    width: 22px;
    height: 19px;
    background: url(./images/double-quotes.svg) center / contain no-repeat;
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
}

.landing-artist-details .story span {
    color: #9a62bc;
}

.landing-artist-images {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 11px;
}

.landing-artist-images div {
    background: center / cover no-repeat;
    padding-bottom: 100%;
}

.landing-artist-images div:first-child {
    grid-row-end: span 2;
}

.landing-vision-banner {
    display: grid;
    gap: 40px;
    justify-content: center;
    padding: 60px 0;
    background: url(./images/vision-banner.jpg) center / cover no-repeat;
    color: #FFFFFF;
}

.landing-vision-banner .header-2-row {
    padding: 0;
}

.landing-vision-banner p {
    max-width: 615px;
    text-align: center;
}

.landing-green-logo {
    display: flex;
    padding: 30px;
    align-items: center;        
}

.landing-green-logo img {
    margin: auto;
    height: 28px;
}
.landing-green-nfts-full {
    display: grid;
    grid-template-columns: 100% max-content;
    gap: 100px;
    padding: 0px 150px 0;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.landing-green-nfts-full p {
    line-height: 25px;
}

.landing-green-nfts-full ul {
    padding-left: 15px;
}

.landing-green-nfts-full p {
    text-align: justify;
}

.landing-green-nfts {
    display: grid;
    grid-template-columns: 50% max-content;
    gap: 100px;
    padding: 50px 150px 0;
    align-items: center;
    position: relative;
    overflow: hidden;
}


.landing-green-nfts p {
    line-height: 25px;
}

.landing-green-nfts-images {
    display: grid;
    grid-template-columns: 230px 200px;
    grid-template-rows: 195px 190px;
    gap: 40px 45px;
}

.landing-green-nfts-images::before {
    content: '';
    width: 295px;
    height: 300px;
    background: linear-gradient(135.8deg, rgba(0, 163, 255, 0.1248) 0%, rgba(0, 18, 181, 0.2132) 106.47%);
    border-radius: 23px 0 0 23px;
    position: absolute;
    top: 0;
    right: 0;
}

.landing-green-nfts-images div {
    background: center / cover no-repeat;
    border-radius: 15px;
    filter: drop-shadow(0px 4px 35px rgba(0, 0, 0, 0.15));
}

.landing-green-nfts-images div.landing-green-nfts-image-1 {
    background-image: url(./images/landing-green-nfts-1.jpg);
    grid-row-end: span 2;
    height: 275px;
    margin: auto 0;
}

.landing-green-nfts-images div.landing-green-nfts-image-2 {
    background-image: url(./images/landing-green-nfts-2.jpg);
}

.landing-green-nfts-images div.landing-green-nfts-image-3 {
    background-image: url(./images/landing-green-nfts-3.jpg);
    margin-right: 10px;
}

.landing-goals,
.landing-imgs {
    display: flex;
    justify-content: center;
    margin: 20px;
}


.landing-goals > div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 120px 115px;
}

.landing-goals > img,
.landing-imgs > img {
    align-items: center;    
}

.landing-imgs {
    position: relative;
}
.landing-imgs::before {
    content: attr(data-credit);
    position: absolute;
    bottom: 30px;
    right: 150px;
}


.landing-goals span {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.landing-goals span::before {
    content: '';
    width: 57px;
    height: 57px;
    background: center / contain no-repeat;
}

.landing-goals span:first-child:before {
    background-image: url(./images/landing-goal-1.svg);
}

.landing-goals span:nth-child(2):before,
.fundraising-benefits span:nth-child(3):before {
    background-image: url(./images/landing-goal-2.svg);
}

.landing-goals span:nth-child(3):before,
.fundraising-benefits span:nth-child(4):before {
    background-image: url(./images/landing-goal-3.svg);
}

.landing-goals span:nth-child(4):before,
.fundraising-benefits span:nth-child(2):before {
    background-image: url(./images/landing-goal-4.svg);
}

.landing-story {
    grid-template-columns: 55% 1fr;
    gap: 20px 88px;
}

.landing-story > div:first-child {
    display: grid;
    gap: 12px;
}

.landing-story > div:first-child:before {
    content: '';
    padding-bottom: var(--paddingBottom);
    background: var(--backgroundImage) center / cover no-repeat;
}

.landing-story .landing-story-details {
    display: grid;
    gap: 20px;
    margin-bottom: 72px;
}

.landing-story .profile {
    margin-bottom: 10px;
}

.landing-story-goals {
    display: flex;
    gap: 9px;
}

.landing-story-goals .goal-icon {
    width: 100px;
    height: 100px;
    margin: 0;
    border-radius: 5px;
}

.comparison-table {
    display: grid;
    grid-auto-rows: 100px;
    text-align: center;
    font-weight: 400;
}

.comparison-table > div {
    display: grid;
    grid-template-columns: 50% repeat(2, 1fr);
    align-items: center;
}

.comparison-table > div:nth-child(even) {
    background-color: #F7F9FA;
}

.comparison-table > div > :first-child {
    text-align: left;
}

.comparison-table .yes-text,
.comparison-table .no-text {
    text-transform: capitalize;
}

.comparison-table .yes-text {
    color: #55C100;
}

.comparison-table .no-text {
    color: #FF2F24;
}

.comparison-table .table-header img {
    margin: auto;
    height: 28px;
}

.comparison-table .info-container {
    display: grid;
    gap: 4px 10px;
}

.info-container .info-text {
    color: #003974;
    font-weight: 300;
}

.arrows-container {
    position: relative;
}

.prev-page,
.next-page {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #EFEFEF url(./images/prev-page.svg) center / 20px no-repeat;
    cursor: pointer;
}

.prev-page {
    left: 60px;
    transform: translateY(-50%);
}

.next-page {
    transform: translateY(-50%) scaleX(-1);
    right: 60px;
}

.show-arrow-enter {
    opacity: 0;
    transition: opacity .3s ease;
}

.show-arrow-enter-active {
    opacity: 1;
}

.show-arrow-exit {
    opacity: 1;
    transition: opacity .3s ease;
}

.show-arrow-exit-active {
    opacity: 0;
}

.page-indicator {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin: 40px 0 80px;
}

.page-indicator span {
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: rgba(0, 57, 116, 0.12);
}

.page-indicator :checked + span {
    background-color: #003974;
}

.page-banner.terms-banner {
    height: 320px;
    background: url(./images/terms-banner.jpg) center bottom / cover no-repeat;
    align-items: center;
}

.page-banner.terms-banner div::before {
    content: unset;
}

.terms-container {
    padding: 70px 170px 0;
    counter-reset: term;
}
.bio-container {
    padding: 70px 85px 0;
}


.bio-container div {
    margin-bottom: 12px;
}

.bio-container p,
.bio-container h6.bio-header,
.terms-container div,
.terms-container h6.terms-header,
.terms-container p {
    margin-bottom: 36px;
}

.bio-container p,
.terms-container p {
    text-align: justify;    
}
.bio-container .bio-end,
.terms-container .terms-date,
.terms-container .terms-end {
    text-transform: uppercase;
    color: #003974;
    letter-spacing: 2px;
}

.bio-container h6,
.bio-container p,
.terms-container h6,
.terms-container p {
    line-height: 36px;
}


.terms-container h6:not(.terms-header):before {
    counter-increment: term;
    content: counter(term) '. ';
}

.bio-container .bio-end,
.terms-container .terms-end {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 20px;
    align-items: center;
}

.bio-container .bio-end::after,
.terms-container .terms-end::after {
    content: '';
    height: 1px;
    background-color: #CFCFCF;
}



.popup-container.donate > form {
    display: grid;
    gap: 35px;
    background-color: #FFFFFF;
    padding: 60px 60px 35px;
    max-width: 760px;
    max-height: 95vh;
    box-sizing: border-box;
    overflow-y: scroll;
    overscroll-behavior-y: contain;
}

.popup-header-container {
    display: grid;
    gap: 10px;
    text-align: center;
    padding: 0 50px;
}

.popup-container.donate .claim-card {
    padding: 0;
    border: 0;
}

.popup-container.donate .claim-card img {
    max-height: 460px;
}

.popup-container.donate .claim-title-profile {
    margin-bottom: 0;
}

.donate-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding: 25px 20px;
    border: 1px solid #C2C2C2;
}

.donate-form .donate-form-header-container {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-auto-flow: dense;
    gap: 20px;
    width: 100%;
}

.donate-form .donate-form-header-container .donate-form-header {
    text-transform: uppercase;
    letter-spacing: 3px;
}

.donated-count {
    padding: 8px 10px;
    grid-column-start: 2;
    margin-bottom: auto;
}

.what-you-get {
    display: grid;
    gap: 10px;
}

.what-you-get span {
    font-weight: 400;
}

.what-you-get ul {
    color: #767676;
    line-height: 23px;
}

.donate-inputs {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(2, max-content);
    gap: 4px 40px;
    margin-bottom: 20px;
    color: #767676;
    text-transform: capitalize;
    line-height: 16px;
}

.donate-inputs .input-container input {
    padding: 12px 16px;
}

.donate-inputs .quantity-container {
    display: grid;
    grid-template-columns: 50px 74px 50px;
}

.donate-inputs button {
    padding: 0;
    border-radius: unset;
}

.donate-inputs .quantity-container input {
    padding: 12px 21px;
    border-radius: unset;
    border-left: 0;
    border-right: 0;
}

.donate-signup {
    display: grid;
    grid-template-columns: 300px;
    gap: 26px;
}

.donate-signup input {
    border: 0;
    border-bottom: 1px solid #A39FEA;
    border-radius: unset;
    padding: 0 0 7px;
}

.donate-form a {
    color: #A39FEA;
}

.donate-fees {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    padding-top: 16px;
    border-top: 1px solid rgba(0, 57, 116, 0.1);
    width: 100%;
    color: rgba(0, 57, 116, 0.7);
}

.donate-fees div {
    display: grid;
    grid-template-columns: max-content 160px;
    gap: 20px;
    align-items: center;
}

.donate-fees b {
    font-weight: 500;
}

.donate-fees img {
    max-width: 72px;
    max-height: 50px;
}

.donate-form + button {
    margin: auto;
    min-width: 200px;
    position: relative;
}

.popup-container.donate p {
    text-align: center;
}

.popup-container.donate .claim-card + a {
    margin: auto;
}

.fhs-grid,
.hkpdf-grid,
.minisaur-grid,
.bff-grid,
.innoverse-grid,
.arles-grid {
    display: grid;
    gap: 35px;
    padding: 0 60px 50px;
    margin: 0 60px 20px;
    border-bottom: 1px solid #DDDDDD;
}

.fhs-grid,
.innoverse-grid,
.arles-grid {
    grid-template-columns: 1fr 420px;
    justify-content: space-between;
}

.innoverse-grid,
.arles-grid {
    padding: 0 150px 50px;
}
.hkpdf-grid {
    grid-template-columns: 420px 1fr;
    align-items: center;
}

.innoverse-grid {
    border-bottom: unset;
}


.minisaur-grid,
.bff-grid {
    gap: 55px;
}

.fhs-grid img.logo,
.hkpdf-grid img.logo,
.bff-grid img.logo {
    width: 255px;
}


.minisaur-grid .text-logo {
    display: flex;
}

.minisaur-grid img.headline {
    width: 380px;
    margin: auto;
}
.minisaur-grid img.logo {
    width: 760px;
    margin: auto;
}
.minisaur-grid img.mini-logo {
    width: 280px;
    padding: 10px;
    margin: auto;
}

.minisaur-grid .card-container {
    display: flex;
}

.minisaur-grid .grid-card {
    width: 400px;
    padding: 10px;
}

.minisaur-grid .card-container .card-img {
    width: 4000px;
    padding: 10px;
}

.bff-grid img.logo {
    margin: auto;
}

.fhs-grid img.poster,
.minisaur-grid img.poster,
.bff-grid img.poster,
.innoverse-grid img.poster,
.arles-grid img.poster {
    border-radius: 15px;
    filter: drop-shadow(6px 9px 40px rgba(0, 57, 116, 0.12));
    margin: auto;
}

.minisaur-grid img.poster {
    border-radius: 0px;
}

.innoverse-grid img.poster {
    box-shadow: 0px 0px 20px #00000026;
    border-radius: 20px;
    opacity: 1;
    width: 420px;
    /*height: 624px;    */
}


.innoverse-grid-logo-img,
.arles-grid-logo-img {
    border-radius: 15px;
    filter: drop-shadow(6px 9px 40px rgba(0, 57, 116, 0.12));
    margin: auto;
    height: 97px;
    padding-left: 70px;
}


.fhs-grid img.poster {
    grid-row-end: span 2;
}

.minisaur-grid img.poster {
    width: 600px;
}
.bff-grid img.poster {
    width: 420px;
}

.minisaur-grid .campaign-text {
    padding-bottom: 50px;
    background-image: url(./images/campaigns/minisaur/watermark.png);
    background-repeat: no-repeat;
    background-size: 420px;
    background-position: center bottom;
}

.fhs-grid p,
.hkpdf-grid p,
.minisaur-grid p,
.bff-grid p,
.innoverse-grid,
.arles-grid {
    line-height: 29px;
}

.fhs-grid .fhs-history {
    max-width: 440px;
}

.innoverse-history,
.arles-history {
    max-width: 440px;
    font-weight: 300;
}

.fhs-grid .fhs-background,
.fhs-grid .innoverse-background,
.fhs-grid .arles-background,
.minisaur-grid .minisaur-background,
.bff-grid .bff-background {
    padding: 70px 40px;
    border-radius: 20px;
}

.fhs-grid .fhs-background {
    grid-column-end: span 2;
    margin-top: 20px;
    background: linear-gradient(112.2deg, rgba(249, 160, 160, 0.43) 1.15%, rgba(217, 165, 31, 0.172) 103.92%);
    text-align: center;
}
.fhs-grid .innoverse-background,
.fhs-grid .arles-background {
    grid-column-end: span 2;
    margin-top: 20px;
    background: linear-gradient(112.2deg, rgba(249, 160, 160, 0.43) 1.15%, rgba(217, 165, 31, 0.172) 103.92%);
    text-align: center;
    font-weight: 300;
}

.minisaur-grid .minisaur-background,
.bff-grid .bff-background {
    background-color: #FFF3E6;
}

.ai-tool-desc {
    display: grid;
    background: linear-gradient(135.8deg, rgba(0, 163, 255, 0.072) 0%, rgba(0, 18, 181, 0.123) 106.47%);
    width: 1160px;
    height: 507px;
    margin: auto auto 100px auto;
    border-radius: 29px;
    padding: 60px;
    grid-template-columns:  repeat(3, 1fr);
    align-items: center;
}

.ai-tool-desc button{
    background:none;
    border:none;
    cursor: pointer;
    padding-top:10px;
    text-transform: capitalize;
    color: rgba(0, 57, 116, 1);
    text-decoration: underline;
    font-weight: 500;
}
.ai-tool-desc button::after {
    content: '';
    border: solid rgba(0, 57, 116, 1);
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}
.ai-tool-desc h4 {
    font-weight: 700;
    font-size: 35px;
    line-height: 41px;
    padding-bottom: 160px;
}
.ai-tool-desc-picturewrap img:first-child {
    width: 200px;
    height: 200px;
    position:relative;
    top:25px;
    left:-60px;
    display: block;
}

.ai-tool-desc-picturewrap img:nth-child(2) {
    width: 200px;
    height: 200px;
    position:relative;
    top:-25px;
    left:60px;
    display: block;
}

.ai-tool-desc img {
    height: 363px;
    width: 272px;
    margin:auto;
    object-fit: cover;
    filter: drop-shadow(0px 4px 55px rgba(0, 0, 0, 0.15));
    border-radius: 20px;
}

.ai-lab-page {
    width:100%;
    padding: 100px 50px 150px 50px;
    background-color: #F0F2F9;
    position: relative;
    z-index: 0;
}

.ai-lab-page select{
    border: none;
    border-bottom: 1px solid #A39FEA;
    max-width: 570px;
    cursor: pointer;
    height: 46px;
}
.ai-lab-page select:focus{
    outline:none;
}

.ai-lab-grid {
    z-index: 1;
    position: relative;
    gap: 5px;
    display: grid;
    background-color: WHITE;
    margin: auto;
    border-radius: 22px;
    padding: 60px 46px 60px 46px;
}

.ai-lab-grid span {
    text-transform: uppercase;
    margin-top: 30px;
    font-family: "Rubik";
}
.ai-lab-grid input {
    width: 570px;
}
.ai-lab-grid > div input {
    width: 475px;
}
.ai-lab-grid li {
    display: inline-block;
    border: 1px solid #A39FEA;
    border-radius: 7px;
    margin-right: 20px;
    cursor: pointer;
    padding: 6px 7px;
    font-weight: 400;
    color: #003974;
    min-width: 50px;
    text-align: center;
 }
.ai-lab-selected-weight {
    background-color: rgba(163, 159, 234, 0.1);
}

 .ai-lab-grid li:hover {
    background-color: rgba(163, 159, 234, 0.1);
 }

 .ai-lab-grid h4 {
    font-family: Abhaya Libre; 
    font-size: 30px;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: 0em;
 }

 .ai-lab-grid button {
    margin-top: 30px;
    background-color: #003974;
    color:white;
    width: 208px;
    height: 51px;
    display: inline-block;
    border:none;
    border-radius: 10px;
    cursor: pointer;
    margin-right: 40px;
}
.ai-page-preview-button {
    max-width: 80px;
    max-height: 30px;
    border-radius: 5px;
    font-size: 14px;
}

.ai-lab-back {
    display: inline;
    color: rgba(163, 159, 234, 1);
    text-transform: uppercase;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.04em;
    text-decoration-line: underline;
    cursor: pointer;
}

.ai-lab-selector {
    display: flex;
}

.ai-lab-selector img{
    width: 272px;
    height: 272px;
    object-fit: cover;
    border-radius: 16px;
}


.ai-result-photo {
    max-width: 468px;
    max-height: 351px;
}

.ai-lab-selector > div {
    padding: 10px 10px;
    display: inline-grid;
    grid-template-columns:  repeat(5, 1fr);
    gap: 6px
}

.ai-lab-selector > div img{
    width: 60px;
    height: 60px;
    cursor: pointer;
    border-radius: 8px;
}

.upload-dropzone {
    border: 2px solid rgba(249, 249, 249, 1);
    width: 450px;
    height: 300px;
    border-radius: 16px;
    display: grid;
    gap: 0 0;
    justify-content: center;
    position: relative;
}

.upload-dropzone button{
    display: none;
}

.upload-dropzone::before {
    content: "";
    width: 30px;
    height: 30px;
    background-image: url(./images/upload.svg);
    margin:119px 209px 16px;
}

.upload-dropzone::after {
    content: 'drag and drop file here';
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 16px;
    line-height: 19px;
    opacity: 0.3;
    margin: 0 auto 116px;
}

.upload-image-viewer {
    border: 2px solid rgba(249, 249, 249, 1);
    width: 450px;
    height: 300px;
    border-radius: 16px;
    display: block;
    gap: 0 0;
    justify-content: center;
}

.upload-image-viewer img {
    display: block;
    margin: auto;
    object-fit: contain;
    width:100%; height:100%;
}

.upload-image-container span {
    color: rgb(163, 159, 234);
    text-decoration: underline;    
    text-transform: uppercase;   
    margin: 21px 265px 0px 265px;
    height: 18px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    cursor: pointer;
    display: inline-block;
}
.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
.ai-lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .ai-lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .ai-lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .ai-lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .ai-lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes ai-lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
.fundraising-banner {
    background: url(./images/fundraising-banner.jpg) center / cover no-repeat;
    color: #FFFFFF;
    padding: 135px 0 170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    text-align: center;

    position: relative;
}

.fundraising-banner::before {
    content: attr(data-credit);
    position: absolute;
    bottom: 30px;
    right: 30px;
}


.fundraising-banner .header-2-row,
.fundraising-benefits-container .header-2-row,
.fundraising-package .header-2-row {
    padding: 0;
}

.fundraising-banner h3.header-2-row {
    letter-spacing: 0em;
}

.fundraising-banner h3.header-2-row::before {
    letter-spacing: .25em;
    text-indent: .25em;
    line-height: 31px;
}

.fundraising-banner p {
    max-width: 470px;
}

.fundraising-banner button {

    border: 0;
    font-weight: 500;
    background-color: #003974;
    color: #FFFFFF;
    min-height: 50px;
    border-radius: 10px;
    text-transform: capitalize;
    cursor: pointer;
    margin: 0;
    box-shadow: 0px 4px 20px rgba(0, 123, 145, 0.15);

    min-width: 215px;
}

.fundraising-benefits-container {
    --backgroundBottom: 80px;
    background: linear-gradient(
        to bottom,
        rgba(249, 160, 160, 0.48) 0%,
        rgba(217, 165, 31, 0.192) calc(100% - var(--backgroundBottom)),
        #FFFFFF calc(100% - var(--backgroundBottom)) 100%
    );
    padding-top: 60px;
    margin-bottom: 25px;
    display: grid;
    gap: 55px;
}

.fundraising-benefits {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 230px;
    justify-content: space-between;
    width: 100%;
    max-width: 1205px;
    margin: 0 auto;
    padding: 0 30px;
    box-sizing: border-box;
    gap: 30px;
}

.fundraising-benefits span {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding: 30px 20px 25px;
    box-shadow: 3px 4px 15px rgba(87, 87, 87, 0.1);
    text-align: center;
    border-radius: 10px;
    background-color: #FFFFFF;
}

.fundraising-benefits span::before {
    content: '';
    width: 66px;
    height: 60px;
    background: center / contain no-repeat;
}

.fundraising-benefits span:first-child:before {
    background-image: url(./images/fundraising-benefit-1.svg);
}

.fundraising-campaigns {
    --page_items: 3;
}

.fundraising-campaign {
    box-shadow: 0px -5px 30px rgba(160, 160, 160, .2);
    border-radius: 15px;
    margin: 30px 0 25px;
    scroll-snap-align: unset;
    grid-auto-rows: max-content;
    background-color: #FFFFFF;
}

.fundraising-campaign.placeholder {
    box-shadow: none;
    background-color: unset;
}

.fundraising-campaign:nth-child(3n + 1) {
    scroll-snap-align: start;
}

.fundraising-campaign img {
    width: 100%;
    height: 195px;
    padding: 40px 20px;
    box-sizing: border-box;
    object-fit: contain;
    border-bottom: 1px solid #CFCFCF;
}

.fundraising-campaign div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    padding: 25px 35px 40px;
}

.fundraising-campaign span {
    font-weight: 500;
    width: 100%;
}

.fundraising-campaign button {
    min-height: 33px;
    min-width: 140px;
}

.fundraising-package {
    display: flex;
    gap: 90px;
}

.fundraising-package .fundraising-package-images {
    display: flex;
    gap: 40px;
}

.fundraising-package .fundraising-package-images::before,
.fundraising-package .fundraising-package-images::after {
    content: '';
    background: center / cover no-repeat;
    filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.15));
    border-radius: 15px;
}

.fundraising-package .fundraising-package-images::before {
    width: 255px;
    height: 335px;
    background-image: url(./images/fundraising-package-image-1.jpg);
    margin-top: 35px;
}

.fundraising-package .fundraising-package-images::after {
    width: 270px;
    height: 280px;
    background-image: url(./images/fundraising-package-image-2.jpg);
}

.fundraising-package .fundraising-package-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    margin-top: 30px;
}

.fundraising-package .fundraising-package-text h4.header-2-row {
    line-height: 40px;
    letter-spacing: .16em;
    text-indent: .16em;
}

.fundraising-package .fundraising-package-text h4.header-2-row::before {
    line-height: 21px;
}

.fundraising-package ul,
.fundraising-case-study ul {
    display: grid;
    gap: 27px;
}

.fundraising-case-study-container {
    --menuwidth: 240px;
    width: calc(
        (100vw - var(--menuwidth))
    );
    display: flex;
    justify-content: center;
}

.fundraising-case-study,
.fundraising-case-study img.poster {
    border-radius: 15px;
}

.fundraising-case-study {
    display: grid;
    gap: 40px;
    width: 770px;
    box-sizing: border-box;
    position: relative;
}

.fundraising-case-study-container:nth-child(odd) .fundraising-case-study {
    background: linear-gradient(135.8deg, rgba(0, 163, 255, 0.1128) 0%, rgba(0, 18, 181, 0.1927) 106.47%), #FFFFFF;
    padding: 45px 370px 50px 70px;
    margin: 0 95px 70px 0;
}

.fundraising-case-study-container:nth-child(even) .fundraising-case-study {
    background: linear-gradient(180deg, rgba(249, 160, 160, 0.48) 0%, rgba(217, 165, 31, 0.192) 100%), #FFFFFF;
    padding: 30px 55px 30px 235px;
    margin: 90px 0 0 150px;
}

.fundraising-case-study img.logo {
    height: 50px;
}

.fundraising-case-study img.logo.blend {
    mix-blend-mode: darken;
}

.fundraising-case-study img.poster {
    position: absolute;
}

.fundraising-case-study-container:nth-child(odd) .fundraising-case-study img.poster {
    width: 420px;
    bottom: -70px;
    right: -95px;
}

.fundraising-case-study-container:nth-child(even) .fundraising-case-study img.poster {
    width: 350px;
    top: -90px;
    left: -150px;
}

.fundraising-partners {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-template-rows: 70px 60px;
    gap: 70px 100px;
    justify-content: center;
    margin-bottom: 110px;
}

.fundraising-partners img {
    height: 100%;
    margin: auto;
}

/* innoverse */
.innoverse-page {
    top: -166px;
    left: -176px;
    width: 2273px;
    height: 2515px;    
    background: transparent url(./images/campaigns/innoverse/background.svg)  0% 0% no-repeat fixed padding-box;
    opacity: 1;    
}

.innoverse-appbar {
    /* Layout Properties */
    top: 72px;
    left: 90px;
    width: 1738px;
    height: 300px;
    background-color: #fff;
    /* UI Properties */
    background: transparent url(./images/campaigns/innoverse/logo.svg) 90px 10px no-repeat padding-box;
    background-size: 323px 107px;
    opacity: 1;    

}
.header-2-row.innoverse-header,
.innoverse-grid,
.asset-grid.innoverse-theme .profile-card h3,
.asset-grid.innoverse-theme .trading-table h5,
.popup-container.disclaimer.innoverse-theme,
.popup-container.redirect.innoverse-theme {
    color: #707070;
}

/*
.header-2-row.innoverse-header {
    
}
*/

.innoverse-grid {
    position: relative;
    align-items: center;
}

.innoverse-grid::before {
    --width: 200px;
    width: var(--width);
    height: calc(var(--width) * 0.8);
    content: '';
    position: absolute;
    bottom: 40px;
    right: -60px;
}

.innoverse-theme button {
    border: 0;
    font-weight: 500;
    background-color: #003974;
    color: #FFFFFF;
    min-height: 50px;
    padding: 0 20px;
    border-radius: 10px;
    text-transform: capitalize;
    cursor: pointer;
    margin: 0;
    box-shadow: 0px 4px 20px rgba(0, 123, 145, 0.15);    
}

.innoverse-theme .actions button {
    border: 0;
    font-weight: 300;
    color: #FFFFFF;
    min-height: 24px;
    padding: 0 20px;
    border-radius: 6px;
    text-transform: capitalize;
    cursor: pointer;
    margin: 0;
    box-shadow: 0px 4px 20px rgba(0, 123, 145, 0.15);    
}


.innoverse-theme button,
.asset-grid.innoverse-theme .trading-table .table-header,
.popup-container.disclaimer.innoverse-theme button,
.popup-container.redirect.innoverse-theme button {
    background-color: #50B748;
}

.asset-grid.innoverse-theme .buy-quantity,
.asset-grid.innoverse-theme .details-header > span,
.asset-grid.innoverse-theme .tabs-grid,
.asset-grid.innoverse-theme .tabs-card .mobile-header {
    color: #B77457;
}

.asset-grid.innoverse-theme .tabs-grid :checked + h6 {
    border-bottom-color: #f3adaa;
}

.asset-grid.innoverse-theme .buy-quantity span::before {
    background-image: url(./images/quantity-brown.svg);
}

.asset-grid.innoverse-theme .trading-table .event-create::before {
    background-image: url(./images/event-list-pink.svg);
}

.asset-grid.innoverse-theme .trading-table h5::before {
    background-image: url(./images/trade-grey.svg);
}



/* arles */
.header-2-row.arles-header,
.arles-grid,
.asset-grid.arles-theme .profile-card h3,
.asset-grid.arles-theme .trading-table h5,
.popup-container.disclaimer,
.popup-container.redirect {
    color: #707070;
}



.popup-container.disclaimer {
    flex-direction: column;
    justify-content: space-between;
}


.header-2-row.arles-header {
    background: url(./images/arles-background-header.svg) left bottom / 300px auto no-repeat;
}

.arles-grid {
    position: relative;
    align-items: center;
}

.arles-grid::before {
    --width: 200px;
    width: var(--width);
    height: calc(var(--width) * 0.8);
    content: '';
    position: absolute;
    bottom: 40px;
    right: -60px;
    background: url(./images/arles-background-grid.svg) center / contain no-repeat;
}

.asset-grid.arles-theme {
    background: #FFFFFF linear-gradient(0deg, rgba(251, 208, 206, 0.08), rgba(251, 208, 206, 0.08));
}

.arles-theme button {
    border: 0;
    font-weight: 500;
    background-color: #003974;
    color: #FFFFFF;
    min-height: 50px;
    padding: 0 20px;
    border-radius: 10px;
    text-transform: capitalize;
    cursor: pointer;
    margin: 0;
    box-shadow: 0px 4px 20px rgba(0, 123, 145, 0.15);    
}

.arles-theme .actions button {
    border: 0;
    font-weight: 300;
    color: #FFFFFF;
    min-height: 24px;
    padding: 0 20px;
    border-radius: 6px;
    text-transform: capitalize;
    cursor: pointer;
    margin: 0;
    box-shadow: 0px 4px 20px rgba(0, 123, 145, 0.15);    
}


.arles-theme button,
.asset-grid.arles-theme .trading-table .table-header,
.popup-container.disclaimer button,
.popup-container.redirect button {
    background-color: #f3adaa;
}

.asset-grid.arles-theme .buy-quantity,
.asset-grid.arles-theme .details-header > span,
.asset-grid.arles-theme .tabs-grid,
.asset-grid.arles-theme .tabs-card .mobile-header {
    color: #B77457;
}

.asset-grid.arles-theme .tabs-grid :checked + h6 {
    border-bottom-color: #f3adaa;
}

.asset-grid.arles-theme .buy-quantity span::before {
    background-image: url(./images/quantity-brown.svg);
}

.asset-grid.arles-theme .trading-table .event-create::before {
    background-image: url(./images/event-list-pink.svg);
}

.asset-grid.arles-theme .trading-table h5::before {
    background-image: url(./images/trade-grey.svg);
}

.popup-container.disclaimer > div,
.popup-container.redirect > div {
    background-color: #FFFFFF;
    padding: 60px 60px 60px;
    margin-bottom: 100px;
    display: grid;
    gap: 35px;
    text-align: center;
    margin-top: 120px;
    overflow-y: scroll;
}

.popup-container.disclaimer button,
.popup-container.redirect button {
    margin: auto;
}

/* Add desktop styles before this line */

.lds-spinner {
    --color: rgba(0, 0, 0, .6);
    --size: 22px;
    display: inline-block;
    position: relative;
    width: var(--size);
    height: var(--size);
}

.lds-spinner div {
    transform-origin: calc(var(--size) * .5) calc(var(--size) * .5);
    animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: calc(var(--size) * .0375);
    left: calc(var(--size) * .4625);
    width: calc(var(--size) * .075);
    height: calc(var(--size) * .225);
    border-radius: 20%;
    background: var(--color);
}

.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}

@keyframes lds-spinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@media (min-width: 992px){
    a:hover,
    button:hover,
    .asset-grid .image-card:hover .show-full,
    .asset-grid .share:not(.copied):hover,
    .asset-grid .close-asset:hover,
    .asset-grid .like:hover,
    .login-container .google-signin:hover,
    .arrows-container .prev-page:hover,
    .arrows-container .next-page:hover {
        opacity: .75;
    }
}

@media (max-width: 991px){
    .hover-info {
        font-size: 18px;
        line-height: 23px;
    }
    .hover-info div {
        font-size: 14px;
        line-height: 17px;
    }
    .nav-switch input {
        display: none;
    }
    .top-nav {
        padding: 30px 24px;
    }
    .top-nav .nav-logo {
        margin: auto;
    }
    .top-nav .search {
        width: 28px;
        height: 28px;
        background: url(./images/search-white.svg) center / contain no-repeat;
    }
    .crew-grid,
    .fundraising-benefits {
        overflow-x: scroll;
        padding-right: 0;
    }
    .crew-grid {
        grid-auto-flow: column;
    }
    .crew-grid span::before {
        width: 100%;
        height: unset;
        padding-bottom: 100%;
    }
    .partners-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        row-gap: 60px;
        padding: 0 30px;
    }
    .landing-footer {
        display: grid;
        gap: 30px;
        grid-template-columns: 1fr;
    }
    .landing-footer img {
        margin: auto;
    }
    .landing-footer p {
        text-align: center;
        margin: auto;
    }
    .footer-message {
        display: grid;
        gap: 15px;
    }
    .footer-socials {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .nav-switch span {
        display: block;
        width: 30px;
        height: 25px;
        background: url(./images/menu.svg) center / contain no-repeat;
    }
    .nav-switch :checked + span {
        background-image: url(./images/close.svg);
    }
    .mobile-nav {
        top: 88px;
        left: 0;
        right: unset;
    }
    .page-banner {
        --align: 80%;
        padding: 0 40px;
    }
    .popup-container.claim button .lds-spinner,
    .login-container .login-submit button .lds-spinner,
    .popup-container.donate button .lds-spinner {
        --size: 20px;
    }
    .popup-container.claim button .lds-spinner,
    .login-container .login-submit button .lds-spinner {
        right: 12px;
    }
    .popup-container.claim button .lds-spinner {
        right: 5px;
    }
    .assets-grid {
        grid-template-columns: repeat(2, 1fr);
        margin-top: 0;
    }
    .assets-grid.controls-grid {
        padding-top: 50px;
    }
    .asset-grid {
        grid-template-columns: 1fr;
        padding: 50px 70px;
    }
    .asset-grid .image-card img,
    .asset-grid .image-card video {
        max-height: calc(100vh - 300px);
    }
    .asset-grid .trading-table h5 {
        padding: 15px 20px;
    }
    .asset-grid .trading-table h5::before {
        margin-right: 10px;
    }
    .asset-grid .trading-table > div {
        grid-template-columns: 20px 1fr 50px repeat(3, 1fr);
        padding: 12px 20px;
    }
    .asset-grid .trading-table .table-header {
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .login-container {
        grid-template-columns: 1fr;
        grid-auto-rows: max-content;
        min-height: calc(100vh - 98px);
    }
    .login-container::before {
        height: 450px;
        background-position-y: 8%;
    }
    .checkout-container {
        grid-template-columns: 1fr max-content;
        gap: 30px;
    }
    .checkout-container > .divider,
    .checkout-container .confirm-card {
        grid-column-end: span 2;
    }
    .checkout-container > .divider {
        border-left: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.6);
    }
    .collection-item {
        width: calc((100% - 60px - 160px) / 2);
    }
    .popup-container.collection > div {
        grid-template-columns: 1fr;
        gap: 40px;
        max-height: 80vh;
        overflow-y: scroll;
        padding: 28px;
    }
    .popup-container.collection > div::after {
        content: '';
        height: 1px;
    }
    .popup-container.collection img {
        max-height: calc(80vh - 56px - 60px);
    }
    .popup-container.collection .collection-description {
        padding: 0;
    }
    .popup-container.collection .collection-description p {
        max-height: unset;
        overflow-y: unset;
    }
    .page-banner.collection-banner div::before {
        width: 25px;
        height: 20px;
        top: 2px;
        left: -8px;
    }
    .asset-grid .tabs-grid {
        gap: 50px;
        margin: 0 10px;
    }
    .asset-grid .tabs-card {
        grid-column-end: unset;
    }
    .asset-grid .bio-tab {
        grid-template-columns: 1fr;
    }
    .asset-grid .bio-tab .profile::before {
        margin: auto;
    }
    .asset-grid .bio-tab .bio-card::before {
        top: -23px;
        left: calc(50% - 23px);
        border: 23px solid transparent;
        border-bottom-color: #CAC8E8;
        border-top: 0;
    }
    .option-grid > label {
        width: auto;
    }
    .goal-icon {
        width: 150px;
        height: 150px;
    }
    .claim-title-profile {
        flex-direction: column;
    }
    .popup-container.options > div,
    .popup-container.claim-received > div,
    .popup-container.donate > form {
        max-height: 80vh;
    }
    .popup-container.claim > div,
    .popup-container.claim-received > div,
    .popup-container.donate > form {
        max-width: 100%;
    }
    .landing-banner {
        height: auto;
        padding: 75px 0 85px;
        justify-content: center;
        grid-template-columns: 1fr;
    }
    .landing-banner-text {
        text-align: center;
        align-items: center;
        gap: 40px;
    }
    .landing-banner-text a {
        margin-top: 5px;
    }
    .landing-banner .landing-banner-images {
        margin: 0 0 44px;
    }
    .landing-banner .landing-banner-images::before {
        content: unset;
    }
    .landing-banner .landing-banner-images div.landing-banner-image-3 {
        background: none;
        position: relative;
    }
    .landing-banner .landing-banner-images div.landing-banner-image-3::before {
        content: '';
        display: block;
        height: 100%;
        background: url(./images/landing-banner-image-3.jpg) center / cover no-repeat;
        position: relative;
        z-index: 1;
        border-radius: 15px;
    }
    .landing-banner .landing-banner-images div.landing-banner-image-3::after {
        content: '';
        position: absolute;
        right: -48px;
        bottom: -44px;
        background: linear-gradient(129.49deg, rgba(187, 232, 94, 0.35) 9.6%, rgba(17, 224, 174, 0.168) 103.33%, rgba(5, 155, 202, 0.168) 103.33%);
        border-radius: 10px;
        width: 110%;
        padding-bottom: 110%;
    }
    .landing-banner p,
    .landing-artist-images {
        padding: 0 150px;
    }
    .header-link,
    .landing-green-nfts,
    .landing-green-nfts-full,
    .landing-goals,
    .landing-imgs,
    .comparison-table > div,
    .fundraising-package {
        padding: 20px 60px;
    }
    .crew-grid,
    .hot-drops,
    .fundraising-benefits {
        gap: var(--padding);
        padding-left: var(--padding);
        scroll-snap-type: x mandatory;
        scroll-padding: 0 var(--padding);
    }
    .crew-grid::after,
    .hot-drops::after,
    .fundraising-benefits::after {
        content: '';
        width: 1px;
        margin-left: -1px;
    }
    .crew-grid span,
    .hot-drops .asset,
    .fundraising-benefits span {
        --menuwidth: 240px;
        width: calc(
            (100vw - var(--menuwidth) - (var(--padding) * (var(--page_items) + 1))) / var(--page_items)
        );
    }
    .fundraising-campaign:nth-child(3n + 1) {
        scroll-snap-align: unset;
    }
    .crew-grid span:nth-child(3n + 1),
    .hot-drops .asset:nth-child(odd),
    .landing-artist,
    .landing-story,
    .fundraising-benefits span:nth-child(odd),
    .fundraising-campaign:nth-child(odd) {
        scroll-snap-align: start;
    }
    .landing-artist,
    .landing-green-nfts,
    .landing-goals,
    .landing-story {
        grid-template-columns: 1fr;
    }
    .landing-artist,
    .landing-story {
        grid-auto-rows: max-content;
    }
    .landing-artist-images {
        order: -1;
    }
    .landing-green-nfts {
        gap: 50px;
    }
    .landing-green-nfts-images {
        margin: 0 auto;
    }
    .landing-green-nfts-images::before {
        content: unset;
    }
    .landing-goals {
        gap: 90px;
    }
    .landing-goals > div {
        gap: 60px 0;
    }
    .landing-story .landing-story-details {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 5px;
    }
    .landing-story-goals {
        justify-content: center;
    }
    .comparison-table > div {
        grid-template-columns: 45% repeat(2, 1fr);
    }
    .comparison-table .info-container {
        display: flex;
        margin: auto;
        transform: translateX(16.5px);
    }
    .comparison-table img.yes-icon,
    .comparison-table img.no-icon {
        height: 23px;
        margin: auto;
    }
    .hover-info {
        position: relative;
        width: 23px;
        height: 23px;
        border-radius: 50%;
        font-weight: 500;
        color: #003974;
        border: 1px solid #003974;
        box-sizing: border-box;
        user-select: none;
    }
    .hover-info div {
        position: absolute;
        width: 150px;
        background-color: #FFFFFF;
        padding: 5px;
        box-sizing: border-box;
        right: 0;
        transform: translate(calc(50% - 11.5px), -100%);
        top: -20px;
        filter: drop-shadow(0px 4px 55px rgba(0, 0, 0, 0.25));
    }
    .show-hover-info-enter {
        opacity: 0;
    }
    .show-hover-info-enter-active {
        opacity: 1;
        transition: opacity .8s ease;
    }
    .show-hover-info-exit {
        opacity: 1;
    }
    .show-hover-info-exit-active {
        opacity: 0;
        transition: opacity .8s ease;
    }
    .page-indicator {
        margin-bottom: 40px;
    }
    .bio-container,
    .terms-container {
        padding: 60px;
    }
    .fhs-grid 
    .hkpdf-grid,
    .minisaur-grid,
    .bff-grid,
    .innoverse-grid,
    .arles-grid {
        padding: 0 0 50px;
        margin-bottom: 0;
        grid-template-columns: 1fr;
    }
    .fhs-grid img.logo,
    .hkpdf-grid img.logo,
    .bff-grid img.logo {
        width: 200px;
        margin: auto;
    }

    .minisaur-grid .campaign-text {
        padding-bottom: 50px;
        background-image: url(./images/campaigns/minisaur/watermark.png);
        background-repeat: no-repeat;
        background-size: 350px;
        background-position: center bottom;
    }
    .minisaur-grid img.headline {
        width: 240px;
        margin: auto;
    }
    .minisaur-grid img.logo {
        width: 360px;
        margin: auto;
    }
    .minisaur-grid img.mini-logo {
        width: 148px;
        padding: 5px;
        margin: auto;
    }
    .fhs-grid::before {
        height: 0;
        padding-bottom: 115%;
    }
    .fhs-grid img {
        width: 200px;
        margin: auto;
    }
    .fhs-grid img.poster{
        order: 1;
        width: auto;
    }
    .fhs-grid .fhs-history,
    .innoverse-grid p,
    .arles-grid p {
        order: -1;
        max-width: unset;
    }
    .fhs-grid .innoverse-history,
    .fhs-grid .arles-history {
        max-width: unset;
        font-weight: 300;
    }
    .fhs-grid .fhs-background {
        grid-column-end: unset;
        margin-top: 0;
    }
    .fhs-grid .innoverse-background,
    .fhs-grid .arles-background {
        grid-column-end: unset;
        margin-top: 0;
        font-weight: 300;
        order: 2;
    }
    .fundraising-benefits-container {
        --backgroundBottom: 100px;
    }
    .fundraising-benefits,
    .fundraising-campaigns {
        --page_items: 2;
    }
    .fundraising-benefits {
        grid-auto-columns: unset;
    }
    .fundraising-benefits span {
        margin-bottom: 19px;
        box-sizing: border-box;
    }
    .fundraising-package {
        padding-top: 80px;
        margin-bottom: 40px;
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }
    .fundraising-case-study {
        width: 530px;
    }
    .fundraising-case-study-container:nth-child(odd) .fundraising-case-study {
        padding: 30px 160px 30px 40px;
        margin: 0 160px 40px 0;
    }
    .fundraising-case-study-container:nth-child(even) .fundraising-case-study {
        padding: 30px 40px 30px 130px;
        margin: 0 0 0 140px;
    }
    .fundraising-case-study-container:nth-child(odd) .fundraising-case-study img.poster {
        width: 300px;
        bottom: -40px;
        right: -160px;
    }
    .fundraising-case-study-container:nth-child(even) .fundraising-case-study img.poster {
        width: 250px;
        top: 80px;
        left: -140px;
    }
    .fundraising-partners {
        grid-template-columns: repeat(2, max-content);
        grid-template-rows: 50px 50px 40px;
    }
    .header-2-row.innoverse-header,
    .header-2-row.arles-header {
        background-size: 250px auto;
    }
    .innoverse-grid::before,
    .arles-grid::before {
        content: unset;
    }
    .innoverse-grid-logo-img,
    .arles-grid-logo-img {
        border-radius: 15px;
        filter: drop-shadow(6px 9px 40px rgba(0, 57, 116, 0.12));
        margin: auto;
        height: 58px;
        padding-left: 70px;
    }
    

}

@media (max-width: 767px){
    button {
        min-height: 45px;
    }
    input {
        padding: 12px;
    }
    input.search {
        background-size: 16px;
        background-position-x: calc(100% - 18px);
        padding-right: 44px;
    }
    .option-grid .option-details ul,
    .what-you-get ul,
    .fundraising-package ul,
    .fundraising-case-study ul {
        padding-left: 15px;
    }
    .landing-banner h1,
    h3.header-2-row {
        font-size: 36px;
        line-height: 42px;
    }
    h1,
    .fundraising-banner h3.header-2-row {
        font-size: 30px;
        line-height: 35px;
    }
    h3,
    h4,
    .asset-grid h3,
    .asset-grid .profile-card h3,
    .popup-container.claim h4,
    .popup-container.claim-received h4,
    .header-link h3,
    .popup-header-container h4 {
        font-size: 24px;
        line-height: 28px;
    }
    .asset-grid .bio-tab h4,
    .landing-artist .profile h3,
    .landing-story .landing-story-details h4 {
        font-size: 22px;
        line-height: 27px;
    }
    .page-banner h3,
    .landing-numbers h3,
    .landing-story h6 {
        font-size: 20px;
        line-height: 24px;
    }
    .checkout-container .checkout-table,
    .asset-grid .details-header > span,
    .asset-grid .asset-counts h4,
    .popup-container.options h4 {
        font-size: 18px;
        line-height: 21px;
    }
    h5,
    input,
    .asset b,
    .asset-grid .ascii-container h5,
    .asset-grid .tabs-card .mobile-header,
    .donate-inputs input,
    .donate-signup input {
        font-size: 16px;
        line-height: 19px;
    }
    button,
    .claim-card button,
    .page-banner,
    .asset-grid .profile-card p,
    .asset-grid .buy-quantity button,
    .center-header,
    .landing-banner p,
    .header-link a,
    h3.header-2-row::before,
    .bio-container h6.bio-header,
    .terms-container h6.terms-header,
    .donate-form .donate-form-header-container .donate-form-header,
    .fundraising-banner h3.header-2-row::before {
        font-size: 14px;
        line-height: 16px;
    }
    .landing-banner p {
        line-height: 23px;
    }
    .top-nav,
    .footer-socials,
    .crew-grid h6,
    .explore-search-grid a,
    .asset-grid h3::after,
    .asset-grid .profile,
    .claim-card .profile,
    .landing-artist .profile,
    .asset,
    .asset-grid .trading-table .table-header,
    .option-grid .option-name-count span,
    .landing-numbers,
    .landing-artist p,
    .landing-vision-banner p,
    .landing-green-nfts p,
    .landing-goals,
    .landing-story p,
    .bio-container h6,    
    .terms-container h6,
    .what-you-get span,
    .donate-inputs .input-container::before,
    .donate-inputs button,
    .fhs-grid p,
    .minisaur-grid p,
    .bff-grid p,
    .innoverse-grid p,
    .arles-grid p,
    .fundraising-banner p {
        font-size: 12px;
        line-height: 14px;
    }
    .landing-artist p,
    .landing-vision-banner p,
    .landing-green-nfts p,
    .landing-story p,
    .bio-container h6,
    .bio-container p,
    .terms-container h6,
    .terms-container p,
    .fhs-grid p,
    .minisaur-grid p,
    .bff-grid p,
    .fundraising-banner p {
        line-height: 21px;
    }
    body,
    .crew-grid span,
    .landing-footer p,
    .bottom-nav,
    .asset-grid .trading-table > div,
    .popup-container.options,
    .option-grid .option-details ul,
    .option-grid .option-name-count b,
    .option-grid .input-container input,
    .claim-input-container span,
    .comparison-table .table-header,
    .hover-info,
    .hover-info div,
    .terms-container .terms-date,
    .bio-container .bio-end,
    .terms-container .terms-end,
    .donated-count,
    .popup-header-container,
    .what-you-get ul,
    .asset-grid .buy-quantity,
    h4.header-2-row::before,
    .fundraising-case-study ul,
    .fundraising-banner button {
        font-size: 10px;
        line-height: 12px;
    }
    body,
    .fundraising-package ul {
        line-height: 16px;
    }
    .option-grid .option-name-count div,
    .option-grid .option-details span,
    .option-grid .input-container::before,
    .option-grid .input-container button,
    .donate-inputs,
    .fundraising-campaign button {
        font-size: 8px;
        line-height: 9px;
    }
    .top-nav {
        padding: 15px 20px;
    }
    .top-nav img {
        height: 20px;
    }
    .top-nav .search {
        width: 20px;
        height: 20px;
    }
    .crew-grid {
        margin-bottom: 0;
    }
    .crew-grid span {
        gap: 5px;
    }
    .crew-grid span:nth-child(3n + 1),
    .fundraising-campaign:nth-child(odd) {
        scroll-snap-align: unset;
    }
    .crew-grid span:nth-child(odd),
    .hot-drops .asset,
    .fundraising-benefits span,
    .fundraising-campaign:nth-child(n) {
        scroll-snap-align: start;
    }
    .crew-grid h6 {
        margin-top: 5px;
    }
    .partners-grid {
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 40px;
    }
    .partners-grid img {
        width: 100px;
    }
    .partners-grid img:nth-child(odd):last-child {
        grid-column-end: span 2;
    }
    .landing-footer {
        padding: 25px 30px 30px;
    }
    .landing-footer p {
        max-width: unset;
        padding: 0 30px;
        line-height: 16px;
        text-align: center;
    }
    .footer-socials > div {
        grid-auto-columns: 25px;
        grid-auto-rows: 25px;
        gap: 16px;
    }
    .footer-socials a.linkedin,
    .footer-socials a.whatsapp {
        background-size: 21px;
    }
    .bottom-nav {
        line-height: 23px;
        padding: 2px 10px;
    }
    .mobile-nav {
        top: 50px;
        padding: 15px 20px;
        gap: 10px;
    }
    .nav-switch span {
        width: 20px;
        height: 15px;
    }
    .page-banner {
        height: 360px;
        padding: 0 0 66px;
        justify-content: flex-end;
        align-items: center;
        line-height: 28px;
        background:
            linear-gradient(0deg, var(--gradient-start), var(--gradient-end)) center bottom / 100% 375px no-repeat,
            var(--background) center / cover no-repeat;
    }
    .explore-search {
        flex-direction: column;
        align-items: unset;
        gap: 20px;
        padding: 20px;
        min-height: unset;
    }
    .explore-search-grid {
        order: 1;
    }
    .explore-search .search-container .lds-spinner {
        right: 18px;
        --size: 20px;
    }
    .assets-grid {
        padding: 0 55px;
        grid-template-columns: 1fr;
        gap: 25px;
    }
    .assets-grid.controls-grid {
        padding-top: 20px;
    }
    .collection-grid {
        padding: 50px 55px;
    }
    .collection-item {
        width: calc(100% - 110px);
        margin-bottom: 25px;
    }
    .asset > div {
        padding: 15px 20px 20px;
        row-gap: 12px;
    }
    .asset-grid {
        padding: 30px 20px;
        gap: 30px;
    }
    .asset-grid > div {
        padding: 30px;
    }
    .asset-grid .image-card img,
    .asset-grid .image-card video {
        max-height: calc(100vh - 250px);
    }
    .asset-grid .show-full {
        bottom: 10px;
        right: 15px;
    }
    .asset-grid .share {
        background-size: 20px;
    }
    .asset-grid .profile-card {
        padding: 28px;
    }
    .asset-grid .profile-ascii,
    .asset-grid .buy-quantity {
        gap: 20px;
        flex-direction: column;
    }
    .asset-grid .share::before {
        left: unset;
        right: 0;
        transform: translate(25%, -100%);
        z-index: 3;
    }
    .asset-grid .profile,
    .collection-description .profile,
    .claim-card .profile,
    .asset .profile {
        gap: 7px;
    }
    .landing-story .profile,
    .landing-artist .profile {
        gap: 12px;
    }
    .landing-artist .profile::before,
    .landing-story .profile::before {
        width: 37px;
        height: 37px;
    }
    .asset-grid .profile::before,
    .collection-description .profile::before,
    .claim-card .profile::before,
    .asset .profile::before {
        width: 30px;
        height: 30px;
    }
    .asset-grid .trading-table > div {
        grid-template-columns: 12px 1fr 35px repeat(3, 1fr);
        padding: 12px 15px;
    }
    .asset-grid .trading-table .table-header {
        margin-bottom: 0;
    }
    .asset-grid .trading-table .event-create::before,
    .asset-grid .trading-table .event-payment::before {
        width: 12px;
        height: 12px;
    }
    .login-container {
        min-height: calc(100vh - 50px);
    }
    .login-container::before {
        height: 250px;
        background-position-y: top;
    }
    .login-container form,
    .checkout-container,
    .checkout-container .confirm-card {
        padding: 30px 20px;
    }
    .page-banner.checkout-banner,
    .page-banner.terms-banner {
        height: 100px;
        padding: 0;
        justify-content: center;
    }
    .checkout-container {
        grid-template-columns: 1fr;
    }
    .checkout-container > .divider,
    .checkout-container .confirm-card {
        grid-column-end: unset;
    }
    .checkout-container .confirm-card button {
        min-width: unset;
    }
    .popup-container.collection > div {
        max-width: 100%;
        max-height: 75vh;
    }
    .asset-grid .profile-card .actions {
        margin-left: auto;
    }
    .asset-grid .divider {
        width: 100%;
        border-top: 1px solid #F0F0F0;
    }
    .asset-grid .asset-counts {
        justify-content: center;
    }
    .asset-grid .asset-counts h4 {
        text-align: center;
    }
    .asset-grid .buy-quantity {
        align-items: center;
    }
    .asset-grid .buy-quantity span::before {
        width: 12px;
        height: 12px;
    }
    .asset-grid .details-header {
        align-self: flex-start;
        margin-bottom: 10px;
    }
    .asset-grid .profile-card p {
        line-height: 28px;
        word-break: break-word;
    }
    .asset-grid .tabs-card {
        display: grid;
        gap: 30px;
        background-color: unset;
        padding: 0;
    }
    .asset-grid .tabs-card .mobile-header {
        text-transform: uppercase;
    }
    .popup-container.options > div,
    .popup-container.claim-received > div {
        padding: 35px 25px 0;
    }
    .popup-container.options > div::after {
        height: 20px;
    }
    .popup-container.options h4 {
        margin-bottom: 24px;
    }
    .option-grid {
        margin-top: 16px;
        gap: 10px;
    }
    .option-grid > label {
        padding: 8px;
        gap: 10px 5px;
    }
    .option-grid .checkbox-container div {
        width: 14px;
        height: 14px;
    }
    .option-grid .checkbox-container :checked + div {
        background-size: 7px;
    }
    .option-grid .option-name-count div,
    .claim-input-container span,
    .donated-count {
        padding: 2px 4px;
    }
    .option-grid .option-name-count {
        gap: 4px;
    }
    .option-grid .input-container {
        margin-top: 14px;
    }
    .option-grid .input-container::before {
        width: 25px;
    }
    .option-grid .input-container input {
        padding: 4px 5px;
        width: 60px;
    }
    .option-grid .input-container button {
        margin-left: 10px;
        padding: 0 15px;
    }
    .asset-grid .trading-table h5 {
        padding: 10px 15px;
    }
    .asset-grid .trading-table h5::before {
        width: 11px;
        height: 12px;
        vertical-align: middle;
    }
    .asset-grid .bio-tab,
    .goal-grid,
    .asset-grid .trading-table {
        margin: 0;
    }
    .asset-grid .bio-tab {
        gap: 35px;
    }
    .asset-grid .bio-tab .profile::before {
        width: 100px;
        height: 100px;
    }
    .asset-grid .bio-tab .bio-card {
        padding: 30px;
        text-align: center;
        gap: 12px;
    }
    .asset-grid .bio-tab .bio-card::before {
        border-width: 17px;
        top: -17px;
        left: calc(50% - 17px);
    }
    .goal-icon {
        width: 80px;
        height: 80px;
        margin: 0 6px 6px 0;
    }
    .popup-container.claim > div,
    .popup-container.disclaimer > div,
    .popup-container.donate > form,
    .popup-container.redirect > div {
        padding: 25px 15px 15px;
    }
    .popup-container.claim > div > h4 {
        margin-bottom: 16px;
    }
    .claim-card {
        padding: 8px;
        gap: 5px;
    }
    .claim-card img {
        max-height: 200px;
    }
    .popup-container.claim-received .claim-card img {
        max-height: 280px;
    }
    .claim-title-profile {
        margin-bottom: 20px;
        gap: 0;
    }
    .claim-input-container {
        --button-width: 130px;
    }
    .landing-banner {
        padding: 25px 45px 0;
        margin-bottom: 60px;
    }
    .landing-banner h1 {
        width: 240px;
        margin: auto;
    }
    .landing-banner-text {
        gap: 20px;
    }
    .landing-banner p {
        padding: 0;
    }
    .header-link,
    .landing-green-nfts,
    .landing-green-nfts-full,
    .landing-imgs,
    .landing-goals,
    .comparison-table > div {
        padding: 0 30px;
    }
    .landing-banner .landing-banner-images {
        grid-template-columns: 140px 98px;
        grid-template-rows: repeat(2, 96px);
        gap: 21px 28px;
        margin: 0 0 24px auto;
    }
    .landing-banner .landing-banner-images div.landing-banner-image-1::before {
        height: unset;
        padding-bottom: 100%;
    }
    .landing-banner .landing-banner-images div.landing-banner-image-1::after {
        width: 140px;
        height: 145px;
        border-radius: 10px;
        left: -22px;
        top: -20px;
    }
    .landing-banner .landing-banner-images div.landing-banner-image-2 {
        margin-right: 0;
    }
    .landing-banner .landing-banner-images div.landing-banner-image-3::after {
        right: -28px;
        bottom: -24px;
    }
    .landing-banner button {
        padding: 0 48px 0 30px;
        background-position: calc(100% - 30px) center;
        background-size: auto 11px;
    }
    .header-link h3::before {
        width: 17px;
        height: 22px;
        margin-right: 9px;
    }
    h3.header-2-row {
        padding: 60px 0 40px;
        letter-spacing: normal;
    }
    .landing-numbers {
        grid-template-columns: 25.87% 19.73% 2.93% 3.47% 1fr 25.87%;
        gap: 5px;
        padding: 0 15px;
    }
    .landing-numbers div.landing-number-16,
    .landing-numbers div.landing-number-14,
    .landing-numbers div.landing-artworks-number,
    .landing-numbers div.landing-number-7,
    .landing-numbers div.landing-number-15,
    .landing-numbers div.landing-number-8,
    .landing-numbers div.landing-number-6,
    .landing-numbers div.landing-number-13 {
        grid-column-end: unset;
        grid-row-end: unset;
        grid-row-start: unset;
        grid-column-start: unset;
    }
    .landing-numbers div.landing-number-16,
    .landing-numbers div.landing-number-13 {
        grid-column-end: span 2;
    }
    .landing-numbers div.landing-number-14,
    .landing-numbers div.landing-artworks-number,
    .landing-numbers div.landing-number-5,
    .landing-numbers div.landing-artists-number,
    .landing-numbers div.landing-number-6,
    .landing-numbers div.landing-number-10 {
        grid-column-end: span 4;
    }
    .landing-numbers div.landing-number-1,
    .landing-numbers div.landing-number-2 {
        grid-column-end: span 3;
    }
    .landing-numbers div.landing-number-15 {
        grid-row-end: span 2;
    }
    .landing-numbers div.landing-number-5,
    .landing-numbers div.landing-number-9,
    .landing-numbers div.landing-number-12 {
        grid-row-end: span 3;
    }
    .landing-numbers div.landing-number-12 {
        grid-row-start: 6;
        grid-column-start: 6;
    }
    .landing-numbers h3::before {
        width: 15px;
        height: 9px;
        left: -5px;
        bottom: 6px;
    }
    .landing-numbers div.landing-artists-number h3::before {
        width: 32px;
        height: 8px;
        right: -8px;
        bottom: 7px;
    }
    .landing-numbers span {
        letter-spacing: 2px;
    }
    .landing-numbers div.landing-artworks-number,
    .landing-numbers div.landing-artists-number {
        gap: 0;
        padding: 28px 0;
    }
    .landing-artist {
        grid-template-columns: 1fr;
        gap: 40px;
    }
    .landing-artist-details {
        gap: 30px;
    }
    .landing-artist-images {
        padding: 0;
        gap: 7px;
    }
    .landing-artist-details .location::before {
        width: 8px;
        height: 10px;
        vertical-align: baseline;
    }
    .landing-artist-details .story {
        margin-top: 5px;
        padding: 25px 30px;
        line-height: 17px;
    }
    .landing-vision-banner {
        padding: 66px 50px;
        gap: 30px;
    }
    .landing-green-nfts {
        gap: 25px;
        overflow: unset;
    }
    .landing-green-nfts-images {
        grid-template-columns: 140px 132px;
        grid-template-rows: repeat(2, 132px);
        gap: 25px 35px;
    }
    .landing-green-nfts-images div.landing-green-nfts-image-1 {
        padding-bottom: 0;
        height: 255px;
    }
    .landing-green-nfts-images div.landing-green-nfts-image-3 {
        margin-right: 0;
    }
    .landing-goals {
        gap: 45px;
    }
    .landing-goals::after {
        background-size: calc(100% - 15px);
        border-radius: 10px;
    }
    .landing-goals span {
        gap: 15px;
    }
    .landing-goals span::before {
        width: 47px;
        height: 47px;
    }
    .landing-story .landing-story-details {
        gap: 20px;
    }
    .landing-story .profile {
        margin: 10px 0 0;
    }
    .landing-story-goals {
        gap: 6px;
    }
    .landing-story-goals .goal-icon {
        width: 48px;
        height: 48px;
    }
    .comparison-table {
        grid-auto-rows: 78px;
    }
    .comparison-table > div {
        grid-template-columns: 40% repeat(2, 1fr);
    }
    .comparison-table .table-header img,
    .comparison-table img.yes-icon,
    .comparison-table img.no-icon {
        height: 13px;
    }
    .hover-info {
        width: 13px;
        height: 13px;
    }
    .comparison-table .info-container {
        gap: 7px;
        transform: translateX(10px);
    }
    .hover-info div {
        width: 100px;
    }
    .landing-mobile-background {
        background: linear-gradient(135.8deg, rgba(0, 163, 255, 0.0984) 0%, rgba(0, 18, 181, 0.1681) 106.47%);
    }
    .page-indicator {
        gap: 4px;
        margin: 20px 0 40px;
    }
    .crew-grid + .page-indicator {
        margin-bottom: 0;
        padding-bottom: 40px;
    }
    .page-indicator span {
        width: 6px;
        height: 6px;
    }
    .bio-container,
    .terms-container {
        padding: 30px;
    }
    .bio-container div,
    .bio-container h6.terms-header,
    .bio-container p,
    .terms-container div,
    .terms-container h6.terms-header,
    .terms-container p {
        margin-bottom: 21px;
    }
    .bio-container .bio-end,
    .terms-container .terms-date,
    .terms-container .terms-end {
        letter-spacing: 1px;
    }
    .bio-container .bio-end,
    .terms-container .terms-end {
        gap: 10px;
    }
    .popup-container.donate > form,
    .popup-container.disclaimer > div,
    .popup-container.redirect > div {
        gap: 15px;
    }
    .popup-header-container {
        gap: 5px;
        padding: 0;
    }
    .donate-form {
        padding: 8px;
        gap: 15px;
    }
    .donate-form .donate-form-header-container .donate-form-header {
        letter-spacing: 1px;
    }
    .what-you-get {
        gap: 5px;
    }
    .donate-inputs {
        gap: 4px 20px;
        margin-bottom: 10px;
    }
    .donate-inputs .input-container::before {
        width: 40px;
    }
    .donate-inputs .input-container input,
    .donate-inputs .quantity-container input {
        padding: 0 12px;
    }
    .donate-inputs .input-container input {
        width: 80px;
    }
    .donate-inputs .quantity-container {
        grid-template-columns: repeat(3, 40px);
    }
    .donate-inputs button {
        min-height: 40px;
    }
    .donate-signup {
        width: 100%;
        grid-template-columns: 1fr;
        gap: 13px;
    }
    .donate-fees {
        flex-direction: column;
        gap: 15px;
    }
    .donate-fees div {
        grid-template-columns: 50px 1fr;
        gap: 10px;
    }
    .donate-fees img {
        max-width: 100%;
        margin: auto;
    }
    .donate-form + button {
        min-width: 165px;
    }
    .minisaur-grid {
        padding: 0 0 50px;
        margin-bottom: 0;
        grid-template-columns: 1fr;
    }    
    .bff-grid {
        padding: 0 0 50px;
        margin-bottom: 0;
        grid-template-columns: 1fr;
    }    
    .fhs-grid,
    .hkpdf-grid,
    .minisaur-grid,
    .bff-grid,
    .innoverse-grid,
    .arles-grid {
        padding-bottom: 20px;
        margin: 0 20px;
        gap: 20px;
    }
    .minisaur-grid,
    .bff-grid {
        gap: 30px;
    }
    .minisaur-grid img.headline {
        width: 150px;
    }
    .minisaur-grid img.logo {
        width: 300px;
    }
    .minisaur-grid img.mini-logo {
        width: 120px;
        padding: 5px;
        margin: auto;
    }
    .minisaur-grid .campaign-text {
        padding-bottom: 40px;
        background-image: url(./images/campaigns/minisaur/watermark.png);
        background-repeat: no-repeat;
        background-size: 300px;
        background-position: center bottom;
    }
    .fhs-grid img.logo,
    .hkpdf-grid img.logo,
    .bff-grid img.logo {
        width: 125px;
    }
    .fhs-grid .fhs-background,
    .fhs-grid .innoverse-background,
    .fhs-grid .arles-background,
    .minisaur-grid .minisaur-background,
    .bff-grid .bff-background {
        padding: 20px;
    }
    h4.header-2-row {
        padding: 40px 0 20px;
    }
    .fundraising-banner {
        padding: 45px 0 75px;
        gap: 40px;
    }
    .fundraising-banner h3.header-2-row::before {
        line-height: 19px;
    }
    .fundraising-banner p {
        padding: 0 40px;
    }
    .fundraising-banner button {
        min-height: 27px;
        min-width: unset;
        border-radius: 5px;
    }
    .fundraising-benefits-container {
        --backgroundBottom: 70px;
        padding-top: 35px;
        gap: 30px;
        margin-bottom: 0;
    }
    .fundraising-benefits-container + .page-indicator,
    .fundraising-campaigns + .page-indicator {
        margin: 10px 0 5px;
    }
    .fundraising-benefits,
    .fundraising-campaigns {
        --page_items: 1;
    }
    .fundraising-benefits span {
        line-height: 18px;
        gap: 20px;
        padding: 20px 15px 15px;
    }
    .fundraising-benefits span::before {
        width: 44px;
        height: 40px;
    }
    h4.header-2-row.campaigns-header {
        padding-bottom: 10px;
    }
    .header-2-row.innoverse-header,
    .header-2-row.arles-header {
        background-size: 150px auto;
    }
    .fundraising-campaign {
        box-shadow: 0px 5px 15px rgb(160 160 160 / 20%);
        margin: 15px 0 20px;
    }
    .fundraising-campaign img {
        height: 95px;
        padding: 20px 10px;
    }
    .fundraising-campaign div {
        padding: 10px 15px;
        gap: 20px;
        align-items: center;
    }
    .fundraising-campaign button {
        min-height: 20px;
        min-width: 70px;
        padding: 0 12px;
        border-radius: 6px;
    }
    .fundraising-package {
        padding-top: 40px;
        gap: 20px;
        margin-bottom: 5px;
    }
    .fundraising-package .fundraising-package-images {
        gap: 18px;
    }
    .fundraising-package .fundraising-package-images::before,
    .fundraising-package .fundraising-package-images::after {
        border-radius: 7px;
    }
    .fundraising-package .fundraising-package-images::before {
        width: 115px;
        height: 150px;
        margin-top: 15px;
    }
    .fundraising-package .fundraising-package-images::after {
        width: 125px;
        height: 125px;
    }
    .fundraising-package ul {
        gap: 16px;
        margin-top: 10px;
    }
    .fundraising-case-study,
    .fundraising-case-study img.poster {
        border-radius: 4px;
    }
    .fundraising-case-study {
        width: calc(100% - 60px);
        gap: 20px;
    }
    .fundraising-case-study-container:nth-child(odd) .fundraising-case-study {
        padding: 15px 130px 15px 20px;
        margin: 0 20px 20px 0;
    }
    .fundraising-case-study-container:nth-child(even) .fundraising-case-study {
        padding: 15px 20px 15px 100px;
        margin-left: 20px;
    }
    .fundraising-case-study img.logo {
        height: 15px;
    }
    .fundraising-case-study-container:nth-child(odd) .fundraising-case-study img.poster {
        width: 130px;
        bottom: -20px;
        right: -20px;
    }
    .fundraising-case-study-container:nth-child(even) .fundraising-case-study img.poster {
        width: 110px;
        top: unset;
        bottom: 20px;
        left: -20px;
    }
    .fundraising-case-study ul {
        gap: 12px;
    }
    .fundraising-case-studies + .page-indicator {
        margin-bottom: 5px;
    }
    .fundraising-partners {
        grid-template-rows: repeat(3, 30px);
        gap: 25px 50px;
    }

}